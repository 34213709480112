/**
 * Created by osirvent on 15/03/2016.
 */
angular
    .module('annexaApp')
    .service('DccumentsFactory',['$q', '$http', 'AnnexaPermissionsFactory', 'HelperService', '$rootScope', '$uibModal', 'dialogs', '$filter', 'RestService', 'Language', 'globalModals', 'AnnexaModalFactory', 'CustomFieldFactory', 'AnnexaFormlyFactory', '$state', 'CommonService', 'WizardHandler', 'DialogsFactory', '$timeout', 'GlobalDataFactory', 'apiAdmin', 'ErrorFactory', 'HelperService', function($q, $http, AnnexaPermissionsFactory, HelperService, $rootScope, $uibModal, dialogs, $filter, RestService, Language, globalModals, AnnexaModalFactory, CustomFieldFactory, AnnexaFormlyFactory, $state, CommonService, WizardHandler, DialogsFactory, $timeout, GlobalDataFactory, apiAdmin, ErrorFactory,HelperService) {

        var factory = {};

        //region Globals
        var startedSelectedTypeResponse = false;
    	var endedSelectedTypeResponse = false;
    	$rootScope.$on('ModifySelectedTypeGlobalDataCache', function(event,args){
    		var callTimeOut = function(step){
    			setTimeout(function() {
    				if(factory.endedSelectedTypeResponse || step > 30){
    					if($rootScope.app.configuration && $rootScope.app.configuration.documents_by_type && $rootScope.app.configuration.documents_by_type.docTypes){
    	            		factory.selectedType = $linq($rootScope.app.configuration.documents_by_type.docTypes).singleOrDefault({ type: undefined }, "x => x.type.id == " + factory.selectedTypeAux);
    	            	}else{
    	                    factory.selectedType = { type: undefined };
    	            	}
    				}else{
    					callTimeOut(step+1);
    				}
				}, 1000);
    		}
    		if(startedSelectedTypeResponse){
    			if(!endedSelectedTypeResponse){
    				callTimeOut(1);
    			}
    		}
    	});
    	var startedDocumentListResponse = false;
    	var endedDocumentListResponse = false;
    	$rootScope.$on('ModifyDocumentListGlobalDataCache', function(event,args){
    		var callTimeOut = function(step){
    			setTimeout(function() {
    				if(factory.endedDocumentListResponse || step > 30){
    					if($rootScope.app.configuration && $rootScope.app.configuration.general_document_type &&$rootScope.app.configuration.general_document_type.value) {
    		                factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
    		            }else{
    		                factory.typeDefault = undefined;
    		            }
    					/*if(HelperService.isSuperAdmin()) {
    						factory.profilesUser = GlobalDataFactory.allProfiles;
    		            } else {*/
    		            	factory.profilesUser = AnnexaPermissionsFactory.getSingleProfileDual('new_document', undefined, true);
    		            //}
    		            factory.sectionsUser = [];
    		            if(HelperService.isSuperAdmin()) {
    		            	factory.sectionsUser = GlobalDataFactory.sections;
    		            } else if ($rootScope.LoggedUser.userSections && $rootScope.LoggedUser.userSections.length) {
    		                _.forEach($rootScope.LoggedUser.userSections, function (value) {
    		                    factory.sectionsUser.push(value.section);
    		                });
    		            }
    		            factory.statuses = GlobalDataFactory.documentStatuses;
    		            if($linq(factory.statuses).count("x => x.id == ''") == 0) {
    		                factory.statuses.unshift({
    		                    id: '',
    		                    icon: 'minus',
    		                    style: 'black',
    		                    name: 'global.literals.all',
    		                    orderStatus: 0
    		                });
    		            }
    				}else{
    					callTimeOut(step+1);
    				}
				}, 1000);
    		}
    		if(startedDocumentListResponse){
    			if(!endedDocumentListResponse){
    				callTimeOut(1);
    			}
      		}
    	});
        factory.type = undefined;
        factory.selectedDocumentsByType = undefined;
        factory.statuses = [];
        factory.document = {};
        factory.profilesUser = [];
        factory.sectionsUser = [];
        factory.documentRegisterEntries = [];
        factory.documentDossiers = [];
        factory.selectedType = { type: undefined };
        factory.selectedDocumentStatus = undefined;
        factory.isAuthenticCopy = false;
        factory.documentEni = undefined;
        factory.pendingErrorJobDocument = {};
        factory.documentThirds = [];
        factory.documentProposals = [];
        factory.documentSessions = [];
        factory.isArchivedDossier = false;
        factory.publishAuditInfo = [];
		factory.emgdeDocMetadata = undefined;
        //endregion

        //region Router (OK, Repasat)

        factory.setSelectedDocumentStatus = function(status) {
            factory.selectedDocumentStatus = status;
        }

        factory.setSelectedType = function(type) {
            if(!type) {
                factory.selectedType = { type: undefined };
            } else {
            	factory.selectedTypeAux = type;
            	factory.startedSelectedTypeResponse = true;
            	factory.endedSelectedTypeResponse = false;
            	if($rootScope.app.configuration && $rootScope.app.configuration.documents_by_type && $rootScope.app.configuration.documents_by_type.docTypes){
            		factory.selectedType = $linq($rootScope.app.configuration.documents_by_type.docTypes).singleOrDefault({ type: undefined }, "x => x.type.id == " + type);
            	}else{
                    factory.selectedType = { type: undefined };
            	}
            	factory.endedSelectedTypeResponse = true;
            }
        }

        factory.getDocument = function(id, archiveModule){
            var promises = [];
            factory.isArchivedDossier = ((archiveModule && archiveModule == true)?true:false);
            promises.push($http({ url: './api/doc/' + id, method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/related/register_entries', method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/related/dossier_transaction_documents', method: 'GET' }));
            promises.push($http({ url: './api/doc/' + id + '/check_document_scanner', method: 'GET'}));
            promises.push($http({ url: './api/jobUploadDocument/' + id + '/pending_error_job_document', method: 'GET'}));
            promises.push($http({ url: './api/doc/' + id + '/thirds', method: 'GET'}));
            promises.push($http({ url: './api/doc/' + id + '/proposals', method: 'GET'}));
            promises.push($http({ url: './api/doc/' + id + '/sessions', method: 'GET'}));
            promises.push($http({ url: './api/plugin/publish/auditInfo/' + id, method: 'GET'}));
			promises.push($http({ url: './api/docEMGDE/' + id +'/emgdeDocMetadata', method: 'GET'}));
			promises.push($http({url: './api/doc/calculateCustomFields/'+id+'/-1', method: 'GET'}));
			
            return $q.all(promises)
                .then(function(data) {
                	var doc = JSOG.decode(data[0].data);
                	if(doc && $state.current && !archiveModule && doc.docStatus == 'ARCHIVED'){
                		factory.document = undefined;
                        factory.documentRegisterEntries = [];
                        factory.documentDossiers = [];
                        factory.isAuthenticCopy = undefined;
						factory.pendingErrorJobDocument = {};
						factory.documentThirds = [];
                    	factory.documentProposals = [];
                    	factory.documentSessions = [];
                    	factory.publishAuditInfo = [];
						factory.emgdeDocMetadata = undefined;
                        DialogsFactory.error( 'global.archive.errorDocumentsArchiveds', $filter('translate')('DIALOGS_ERROR_MSG'));
                        $state.transitionTo('annexa.dashboard');
                	}else{
	                    factory.document = doc;
	                    factory.documentRegisterEntries = JSOG.decode(data[1].data);
	                    factory.documentDossiers = JSOG.decode(data[2].data);
                    	if(factory.documentDossiers){
                    		factory.documentDossiers = $linq(factory.documentDossiers).select("x => x.dossierTransaction").toArray();
                    	}
	                    factory.isAuthenticCopy = JSOG.decode(data[3].data);
	                    factory.pendingErrorJobDocument = JSOG.decode(data[4].data);
                		factory.documentThirds = JSOG.decode(data[5].data);
                    	factory.documentProposals = JSOG.decode(data[6].data);
                    	factory.documentSessions = JSOG.decode(data[7].data);
                    	factory.publishAuditInfo = JSOG.decode(data[8].data);
						factory.emgdeDocMetadata = JSOG.decode(data[9].data);
						var cfsCalculated = JSOG.decode(data[10].data);
						if(factory.document.customFields){
							if(cfsCalculated.showCFs){
								var keys = Object.keys(cfsCalculated.showCFs);
	                            _.forEach(keys, function(key){
	                            	var indexCFk = $linq(factory.document.customFields).indexOf("x => x.id == "+key );
	                            	if(indexCFk > -1){
	                            		if(cfsCalculated.showCFs[key]){
		            						factory.document.customFields[indexCFk].invisible = true;
	                            		}else{
	                            			factory.document.customFields[indexCFk].invisible = false;
	                            		}
	                            	}
	                            });
							}
							if(cfsCalculated.showCFGs){
								var deleteGCFFromGroup = function(groups, key, show){
									var deleted = false;
									if(groups){
										var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
										if(indexCFk > -1){
											if(show){
												groups[indexCFk].invisible = true;
											}else{
												groups[indexCFk].invisible = false;
											}
											deleted = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													deleted = deleteGCFFromGroup(cfAux.groups, key, show);
												}
											});
										}
									}
									return deleted;
								}
								var keys = Object.keys(cfsCalculated.showCFGs);
	                            _.forEach(keys, function(key){
	                            	var deleted = false;
	                            	_.forEach(factory.document.customFields, function(cfAux){
	                            		if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	                            			deleted = deleteGCFFromGroup(cfAux.groups, key, cfsCalculated.showCFGs[key]);
	                            		}
	                            	});
	                            });
							}
							var calculateGroupListValues = function(groups, cfgs){
								_.forEach(groups, function(cf){
									if(cf.relatedCustomField && cf.relatedCustomField.fromQuery && cf.relatedCustomField.query && cf.relatedCustomField.query.id && cfgs && cfgs[cf.id]){
										var labelPropField = [];
										if(cf.labelPropField){
											labelPropField = cf.labelPropField.split("--@--@--");
										}
										var lvaluesActual = [];
										if(cf.identifierField && labelPropField && labelPropField.length > 0){
											_.forEach(cfgs[cf.id], function(lv){
												if(lv[cf.identifierField]){
													var label = '';
													_.forEach(labelPropField, function(lp){
														label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
													});
													if(cf.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
														var cflvadded = {
															id:lv[cf.identifierField],
															language1:label,
															language2:label,
															language3:label,
															value:lv[cf.identifierField],
															parentValue:{id:lv[cf.parentField]}
														}
														lvaluesActual.push(cflvadded);
													}else{
														var cflvadded = {
															id:lv[cf.identifierField],
															language1:label,
															language2:label,
															language3:label,
															value:lv[cf.identifierField]
														}
														lvaluesActual.push(cflvadded);
													}
													if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
														_.forEach(cf.relatedCustomFields, function(rc){
															if(rc.customFieldValue && rc.customFieldTemplateTag){
																cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
															}
														})
													}
												}
											});
											cf.relatedCustomField = angular.copy(cf.relatedCustomField);
											cf.relatedCustomField.listValues = lvaluesActual
										}
									} else if(cf.relatedCustomField && cf.relatedCustomField.frontendType === 'CF_GROUP' && cf.groups && cfgs){
										calculateGroupListValues(cf.groups, cfgs);
									} 
								});
							}
							_.forEach(factory.document.customFields, function(cf){
								if(cfsCalculated && cfsCalculated.queryCFs){ 
									if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryCFs && cfsCalculated.queryCFs[cf.id]){
										var labelPropField = [];
										if(cf.labelPropField){
											labelPropField = cf.labelPropField.split("--@--@--");
										}
										var lvaluesActual = [];
										if(cf.identifierField && labelPropField && labelPropField.length > 0){
											_.forEach(cfsCalculated.queryCFs[cf.id], function(lv){
												if(lv[cf.identifierField]){
													var label = '';
													_.forEach(labelPropField, function(lp){
														label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
													});
													if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
														var cflvadded = {
															id:lv[cf.identifierField],
															language1:label,
															language2:label,
															language3:label,
															value:lv[cf.identifierField],
															parentValue:{id:lv[cf.parentField]}
														}
														lvaluesActual.push(cflvadded);
													}else{
														var cflvadded = {
															id:lv[cf.identifierField],
															language1:label,
															language2:label,
															language3:label,
															value:lv[cf.identifierField]
														}
														lvaluesActual.push(cflvadded);
													}
													if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
														_.forEach(cf.relatedCustomFields, function(rc){
															if(rc.customFieldValue && rc.customFieldTemplateTag){
																cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
															}
														})
													}
												}
											});
											cf.customField = angular.copy(cf.customField);
											cf.customField.listValues = lvaluesActual
										}
									} else if(cf.customField && cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryCFGs){
										calculateGroupListValues(cf.groups, cfsCalculated.queryCFGs);
									}
								}
							});
							if(cfsCalculated.calculatedCFs){
								var keys = Object.keys(cfsCalculated.calculatedCFs);
	                            _.forEach(keys, function(key){
	                            	var cfToChange = $linq(factory.document.customFields).firstOrDefault(undefined, "x => x.id == "+key );
	            					if(cfToChange){
	            						cfToChange.value = cfsCalculated.calculatedCFs[key];
	            					}
	                            });
							}
							if(cfsCalculated.calculatedCFGs){
								var calculateGCFFromGroup = function(groups, key, calculateds){
									var calc = false;
									if(groups){
										var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
										if(cFk){
											cFk.value = calculateds[key];
											calc = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
												}
											});
										}
									}
									return calc;
								}
								var keys = Object.keys(cfsCalculated.calculatedCFGs);
	                            _.forEach(keys, function(key){
	    	 						var calc = false;
	        						_.forEach(factory.document.customFields, function(cfAux){
	        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	        								calc = calculateGCFFromGroup(cfAux.groups, key, cfsCalculated.calculatedCFGs);
	        							}
	        						});
	                        	});
							}
						}
					}
                }).catch(function(error) {
                    factory.document = undefined;
                    factory.documentRegisterEntries = [];
                    factory.documentDossiers = [];
                    factory.isAuthenticCopy = undefined;
					factory.pendingErrorJobDocument = {};
					factory.documentThirds = [];
                    factory.documentProposals = [];
                    factory.documentSessions = [];
                    factory.publishAuditInfo = [];
					factory.emgdeDocMetadata = undefined;
            });
        };

        factory.getDocumentLists = function() {
        	factory.startedDocumentListResponse = true;
        	factory.endedDocumentListResponse = false;
            if($rootScope.app.configuration && $rootScope.app.configuration.general_document_type &&$rootScope.app.configuration.general_document_type.value) {
                factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
            }else{
                factory.typeDefault = undefined;
            }

            /*if(HelperService.isSuperAdmin()) {
				factory.profilesUser = GlobalDataFactory.allProfiles;
            } else {*/
            	factory.profilesUser = AnnexaPermissionsFactory.getSingleProfileDual('new_document', undefined, true);
            //}

            factory.sectionsUser = [];
            if(HelperService.isSuperAdmin()) {
            	factory.sectionsUser = GlobalDataFactory.sections;
            } else if ($rootScope.LoggedUser.userSections && $rootScope.LoggedUser.userSections.length) {
                _.forEach($rootScope.LoggedUser.userSections, function (value) {
                    factory.sectionsUser.push(value.section);
                });
            }

            factory.statuses = GlobalDataFactory.documentStatuses;

            if($linq(factory.statuses).count("x => x.id == ''") == 0) {
                factory.statuses.unshift({
                    id: '',
                    icon: 'minus',
                    style: 'black',
                    name: 'global.literals.all',
                    orderStatus: 0
                });
            }
            factory.endedDocumentListResponse = true;
            
            return true;
        }

        factory.getDocumentsTypeType = function (type) {
            if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes != 0) {
                factory.type = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, " x => x.id == " + type);

                if(factory.type) {
                    return $q.resolve(true);
                } else {
                    $timeout(function() { $state.go('annexa.dashboard'); });
                    return $q.reject("No document type");
                }
            } else {
                return RestService.findOne('DocumentType', type)
                    .then(function (data) {
                        factory.type = JSOG.decode(data.data);
                    }).catch(function(error){});
            }
        }

        //endregion

        //region DocumentViewMode

        factory.getDocumentViewModeElementType = function(mime) {
            var retType = 'unknown';

            switch (mime) {
                case 'application/msword':
                case 'application/rtf':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.oasis.opendocument.text':
                    retType = 'word';
                    break;
                case 'application/pdf':
                    retType = 'pdf';
                    break;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    retType = 'powerpoint';
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    retType = 'excel';
                    break;
                case 'text/plain':
                case 'text/xml':
                case 'text/x-sql':
                    retType = 'text';
                    break;
            }

            return retType;
        };

        factory.getDocumentViewModeElementIcon = function(type) {
            var fileType = "fa-file-o";
            var fileName = "global.fileTypes.unknown";

            switch (type) {
                case 'folder':
                    fileType = "fa-folder";
                    fileName = "global.fileTypes.folder";
                    break;
                case 'excel':
                    fileType = "fa-file-excel-o";
                    fileName = "global.fileTypes.excel";
                    break;
                case 'pdf':
                    fileType = 'fa-file-pdf-o';
                    fileName = "global.fileTypes.pdf";
                    break;
                case 'word':
                    fileType = "fa-file-word-o";
                    fileName = "global.fileTypes.word";
                    break;
                case 'image':
                    fileType = "fa-file-image-o";
                    fileName = "global.fileTypes.image";
                    break;
                case 'text':
                    fileType = "fa-file-text-o";
                    fileName = "global.fileTypes.text";
                    break;
                case 'audio':
                    fileType = "fa-file-audio-o";
                    fileName = "global.fileTypes.audio";
                    break;
                case 'powerpoint':
                    fileType = "fa-file-powerpoint-o";
                    fileName = "global.fileTypes.powerpoint";
                    break;
                case 'video':
                    fileType = "fa-file-video-o";
                    fileName = "global.fileTypes.video";
                    break;
            }

            return [fileType, fileName];
        };

        //endregion

        //region Modal Nou Document (OK, Repasat)

        //region typeSelect

        var showFileTemplate = function(value) {
            return value == 'NOT_REQUIRED' ? false : true;
        }

        factory.typeSelected = function(item, model, fields, scope, optionAux) {
            if(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id){
            	model.template = model.templateDefaultBooks;
            }else{
            	model.template = {};
            }

            var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + item.id);
            if(optionAux && optionAux.length > 0){
            	typeSelected = $linq(optionAux).firstOrDefault(undefined, "x => x.id == " + item.id);
            }

            if(typeSelected) {
                model.showFile = showFileTemplate(typeSelected.documentFileAttachment);
                model.showTemplate = showFileTemplate(typeSelected.documentTemplateSelection);
				model.showPhysical = showFileTemplate(typeSelected.documentPhysicalSelection);
            }

            if(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id){
            	model.inputType = 'template';
            }else{
            	model.inputType = ((!model.showFile && model.showTemplate)? 'template' : ((!model.showFile && !model.showTemplate && model.showPhysical)? 'physical' : 'file' ));
            }

            if(!(model.templateDefaultBooks && model.templateDefaultBooks.$selected && model.templateDefaultBooks.$selected.id) && $rootScope.esetMode && !(optionAux && optionAux.length > 0)  && (model.module == 'REGENTRADA' || model.module == 'REGSALIDA')) {
                model.showFile = true;
                model.showTemplate = false;
				model.showPhysical = false;
				model.inputType = 'file';
            }

            //Templates
            var indexFieldTemplate = $linq(fields).indexOf("x => x.key == 'template'");
            if(indexFieldTemplate != -1) {
                fields[indexFieldTemplate].templateOptions.options = factory.getTemplateOptions(model);
                $rootScope.$broadcast('treeTemplatesChange');
                if (fields[indexFieldTemplate].formControl) {
                    fields[indexFieldTemplate].formControl.$validate();
                }
            }

            //Archive Classification
            factory.setFileArchiveClassification(model);
        }

        //endregion

        //region document - template

        factory.hideDocumentTemplate = function(model) {
            var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

            if(model.wizard || model.wizard_AllInOne) {
                return true;
            }

            if(typeSelected) {
                return typeSelected.documentTemplateSelection == 'NOT_REQUIRED' && typeSelected.documentFileAttachment == 'NOT_REQUIRED' && typeSelected.documentPhysicalSelection == 'NOT_REQUIRED';
            }

            return false;
        }

        factory.selectFile = function(model, files) {
            model.template = { $selected: undefined };
			model.physicalLength = undefined;
			model.physicalWidth = undefined;
			model.physicalDepth = undefined;
			model.physicalWeight = undefined;
			model.physicalVolume = undefined;
            model.physicalUnits = undefined;
			model.physicalLocation = undefined;
			model.physicalSupport = undefined;
            if(files && files[0]) {
                model.file = files[0];
            } else {
                model.file = undefined;
            }
            
            if (!$rootScope.esetMode && model.inputType == 'file' && model.file && model.file.name && !model.title) {
                model.title = model.file.name.substring(0, model.file.name.lastIndexOf("."));
            }
        }

        factory.setTemplateArchiveClassification = function(model) {
            if(!model.modifyArchiveClassification && !model.tramArchiveClassification && model.inputType == 'template' && model.template && model.template.$selected && model.template.$selected.id) {
                var templateSelected = $linq(GlobalDataFactory.templates).singleOrDefault(undefined, "x => x.id == " + model.template.$selected.id);

                if(templateSelected && templateSelected.archiveClassification) {
                    model.archiveClassification.$selected = {
                        id: templateSelected.archiveClassification.id,
                        title: templateSelected.archiveClassification[Language.getActiveColumn()]
                    }
                } else {
                    model.archiveClassification = {};
                }
            }
        }

        factory.setFileArchiveClassification = function(model) {
            if (!model.modifyArchiveClassification && !model.tramArchiveClassification && model.inputType == 'file') {
                var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

                if (typeSelected && typeSelected.archiveClassification) {
                    model.archiveClassification.$selected = {
                        id: typeSelected.archiveClassification.id,
                        title: typeSelected.archiveClassification[Language.getActiveColumn()]
                    }
                } else {
                    model.archiveClassification = {};
                }
            }
        }

        factory.selectTemplate = function(model, item) {
            if(item && item.id){
				model.template = { $selected: item };
	            model.template.$selected.id = Math.abs(model.template.$selected.id);
			}else{
				model.template = { $selected: undefined };
			}
            model.file = undefined;
			model.physicalLength = undefined;
			model.physicalWidth = undefined;
			model.physicalDepth = undefined;
			model.physicalWeight = undefined;
			model.physicalVolume = undefined;
			model.physicalUnits = undefined;
			model.physicalLocation = undefined;
			model.physicalSupport = undefined;
            factory.setTemplateArchiveClassification(model);
        }

		factory.selectPhisical = function(model) {
            model.template = { $selected: undefined };
			model.file = undefined;
        }

        factory.requiredValidator = function(model,title_field) {
            if(model) {
                var typeSelected = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + model.typeSelect);

                if (typeSelected) {
                    title_field.templateOptions.placeholder = typeSelected[Language.getActiveColumn()];

                    if (typeSelected.documentTemplateSelection == 'REQUIRED' && (!model.template || !model.template.$selected || !model.template.$selected.id)) {
                        return false;
                    } else if (typeSelected.documentFileAttachment == 'REQUIRED' && (!model.file || !model.file.name)) {
                        return false;
                    } else if (typeSelected.documentPhysicalSelection == 'REQUIRED' && (model.physicalLength == undefined || model.physicalWidth == undefined || model.physicalDepth == undefined || model.physicalSupport == undefined || model.physicalLocation == undefined)) {
                        return false;
                    } else if (typeSelected.documentTemplateSelection == 'DOCUMENT_OR_TEMPLATE' && (!model.file || !model.file.name) && (!model.template || !model.template.$selected || !model.template.$selected.id)  && (model.physicalLength == undefined || model.physicalWidth == undefined || model.physicalDepth == undefined || model.physicalSupport == undefined || model.physicalLocation == undefined)) {
                        return false;
                    } else if (typeSelected.documentFileAttachment == 'DOCUMENT_OR_TEMPLATE' && (!model.template || !model.template.$selected || !model.template.$selected.id) && (!model.file || !model.file.name) && (model.physicalLength == undefined || model.physicalWidth == undefined || model.physicalDepth == undefined || model.physicalSupport == undefined || model.physicalLocation == undefined)) {
                        return false;
                     } else if (typeSelected.documentPhysicalSelection == 'DOCUMENT_OR_TEMPLATE' && (!model.template || !model.template.$selected || !model.template.$selected.id) && (!model.file || !model.file.name) && (model.physicalLength == undefined || model.physicalWidth == undefined || model.physicalDepth == undefined || model.physicalSupport == undefined || model.physicalLocation == undefined)) {
                        return false;
                    }
                } else {
                    title_field.templateOptions.placeholder = '';
                }
            }else{
                return false;
            }
            return true;
        }

        factory.getTemplateOptions = function(model) {
            var clearNode = function(options, optionsToModify) {
                if(optionsToModify && optionsToModify.length > 0) {
                    var optionsAux = {};
                    var modifyOptions = [];
                    var modifyOptionsAux = [];

                    for(var key in options) {
                        modifyOptions = $linq(options[key]).where(function(x) {
                            var contains = $linq(optionsToModify).count(function(y) {
                                if(y == x.id) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });

                            if(contains > 0) {
                                return true;
                            } else {
                                return false;
                            }
                        }).toArray();

                        _.forEach(modifyOptions, function(value, index) {
                            if(options[value.id] && options[value.id].length > 0) {
                                optionsToModify.splice(optionsToModify.indexOf(value.id), 1);
                            } else {
                                modifyOptionsAux.push(index);
                            }
                        });

                        var aux = $linq(options[key]).where(function(x) {
                            var contains = $linq(optionsToModify).count(function(y) {
                                if(y == x.id) {
                                    return true;
                                } else {
                                    return false;
                                }
                            });

                            if(contains > 0) {
                                if((options[x.id] && options[x.id].length > 0) || x.id < 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                if((!options[x.id] || options[x.id].length == 0) && x.id > 0) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        }).toArray();

                        if(aux && aux.length > 0) {
                            optionsAux[key] = aux;
                        }
                    }

                    return clearNode(optionsAux, modifyOptionsAux);
                } else {
                    return options;
                }
            }

            var templateOptions = $linq(angular.copy(GlobalDataFactory.templateTree)).where(function(x) {
            	var haveProfile = true;
            	if(x.id < 0){
            		if(x.profiles && x.profiles.length > 0){
            			var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                            if(y.profile){
                                return (x.profile.id == y.profile.id);
                            }
                            else{
                                return true;
                            }
                        }).toArray();
                        if(profiles && profiles.length > 0){
                        	haveProfile = true;
                        }
                        else{
                        	haveProfile = false;
                        }
            		}
            	}
            	
                if((haveProfile) && (!x.documentType || x.documentType.id == model.typeSelect)) {
                    return true;
                } else {
                    return false;
                }
            }).toArray();

            var tmpOptions = CommonService.getTreeData(angular.copy(templateOptions), Language.getActiveColumn());
            for(var key in tmpOptions) {
                tmpOptions[key] = $linq(tmpOptions[key]).where(function(x) {
                    if(!x.parent && x.id > 0) {
                        return false;
                    } else {
                        return true;
                    }
                }).toArray();
            }

            var options = {};
            var toFixOptions = [];
            for(var key in tmpOptions) {
                if(tmpOptions[key].length > 0) {
                    options[key] = tmpOptions[key];
                } else {
                    toFixOptions.push(key);
                }
            }
            var optionsToReturn = {};
            if(toFixOptions.length > 0) {
                optionsToReturn = clearNode(options, toFixOptions);
            } else {
                optionsToReturn =  options;
            }
            if(optionsToReturn){
                if (Object.keys(optionsToReturn).length == 0) {
                    optionsToReturn = {0:[]};
                }
            }else{
                optionsToReturn = {0:[]};
            }
            return optionsToReturn;
        }

        //endregion

        //region archiveClassification

        factory.hideArchiveClassification = function (model) {
            if($rootScope.esetMode && model.tramArchiveClassification) {
                if(model.archiveClassification.$selected) {
                    return true;
                } else {
                    return false;
                }
            } else if($rootScope.esetMode && model.archiveClassification.$selected) {
                if(model.modifyArchiveClassification){
                    return false;
                }else{
                    return true;
                }
            } else {
                return false;
            }
        }

        //endregion

        var openSelectMasiveFilesModal = function(preCloseModalFunction, self, archiveClassification, extra, documentType, dossierSection) {
            var addNewDocuments = function () {
                if (this.annexaFormly.form.$valid) {
                    var selfAux = this;
                    var data = selfAux.annexaFormly.model.modal_body;
                    var documentNames = [];
                    if(data && data.file) {
                        _.forEach(data.file, function(index){
                            documentNames.push(index.name);
                        });
                        factory.getDocumentModal(documentNames, self, preCloseModalFunction, archiveClassification, extra, data.file, 'global.buttons.document.news', '&#xe89c', documentType, true, undefined, undefined, dossierSection);
                    }
                    selfAux.close();
                }
            }

            var modalGetNewDocuments = angular.copy(globalModals.uploadMultiFile);
            modalGetNewDocuments.title = 'global.buttons.document.news';
            modalGetNewDocuments.icon = '&#xe89c';
            modalGetNewDocuments.annexaFormly.model = {};
            modalGetNewDocuments.annexaFormly.model.modal_body = {};
            modalGetNewDocuments.annexaFormly.options = {};
            modalGetNewDocuments.annexaFormly.options.formState = {readOnly: false};
            AnnexaFormlyFactory.showModal('modalUpladaNewDocuments', modalGetNewDocuments, addNewDocuments, false);
        }
        
        var openSelectMasiveFilesModalAddScann = function(preCloseModalFunction, self, archiveClassification, extra, documentType) {
            var addNewDocuments = function () {
            	var selfAux = this;
            	var documentEntryPath = $rootScope.app.configuration.scan_document_entry_path.value;
            	
            	if(!documentEntryPath && (documentEntryPath == "" || documentEntryPath == " ")) {
            		DialogsFactory.error( 'global.literals.errorScanPathNotDefined', $filter('translate')('DIALOGS_ERROR_MSG'));
            	}else if (this.annexaFormly.form.$valid) {
                    var data = selfAux.annexaFormly.model.modal_body;
                    var documentNames = [];
                    if(data && data.file) {
                        var validExt = $rootScope.app.configuration.scan_document_accept_extensions.value;
                    	var valid = checkFileExt(data.file, validExt);
                    	if(!valid) {
                    		DialogsFactory.error( $filter('translate')('global.literals.errorScanDocumentExtension', { ext: validExt}), $filter('translate')('DIALOGS_ERROR_MSG'));
                    	} else {
                    		RestService.insertMultiPartData('./api/doc/add_files_scann', document, data.file)
			                    .then(function(data) {
	                                DialogsFactory.notify('global.literals.addToScanOk');
			                    }).catch(function(error) {
			                    if (error && error.message && error.message.toUpperCase().indexOf('PATH') != -1) {
			                		DialogsFactory.error( 'global.literals.errorScanPathNotExist', $filter('translate')('DIALOGS_ERROR_MSG'));
			                    } else if(error && error.code && error.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.message)?'<br/><br/>'+error.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
								} else {
			                		DialogsFactory.error( 'global.literals.errorAddingScanDocument', $filter('translate')('DIALOGS_ERROR_MSG'));
			                    }
			                });	
                            selfAux.close();
                    	}
                    }
                }
            }

            var modalGetNewDocuments = angular.copy(globalModals.uploadMultiFile);
            modalGetNewDocuments.title = 'global.literals.addScanner';
            modalGetNewDocuments.icon = '&#xe89c';
            modalGetNewDocuments.annexaFormly.model = {};
            modalGetNewDocuments.annexaFormly.model.modal_body = {};
            modalGetNewDocuments.annexaFormly.options = {};
            modalGetNewDocuments.annexaFormly.options.formState = {readOnly: false};
            
            var accept = $rootScope.app.configuration.scan_document_accept_extensions.value;
            
            if(accept){
            	_.forEach(modalGetNewDocuments.annexaFormly.fields[0].fieldGroup, function(value, index) {
            		if(value.key == "file") {
                        value.data.accept = accept;
                    }
                });
            }
                
            
            AnnexaFormlyFactory.showModal('modalUpladaNewDocuments', modalGetNewDocuments, addNewDocuments, false);
        }
        
        function checkFileExt(val, validExt) {
            var valid = true;
            var regExp, exp, fileExt;
            if (validExt) {
                exp = validExt.trim().replace(/[,\s]+/gi, "|").replace(/\./g, "\\.").replace(/\/\*/g, "/.*");
                regExp = new RegExp(exp);
            }

            if (validExt && val) {
                for (var i = 0; i < val.length; i++) {
                    var file = val[i];
                    fileExt = "." + file.name.split('.').pop().toLowerCase();
                    valid = regExp.test(file.type) || regExp.test(fileExt);

                    if (!valid) {
                        return valid;
                    }
                }
            }

            return valid;
        }

        factory.openMasiveDocumentModalFolderFromDoc = function (preCloseModalFunction, self, archiveClassification, extra, documentType) {
            openSelectMasiveFilesModal(preCloseModalFunction, self, archiveClassification, extra, documentType);
        }

        factory.openMasiveDocumentModalFolder = function(preCloseModalFunction, self, archiveClassification, extra, documentType, dossierSection){
            openSelectMasiveFilesModal(preCloseModalFunction, self, archiveClassification, extra, documentType, dossierSection);
        }
        
        factory.openMasiveDocumentModalAddScann = function(preCloseModalFunction, self, archiveClassification, extra, documentType){
        	openSelectMasiveFilesModalAddScann(preCloseModalFunction, self, archiveClassification, extra, documentType);
        }

        factory.openMasiveDocumentModalScan = function(preCloseModalFunction, self, archiveClassification, extra, documentType){
            factory.getDocumentFromScanner()
                .then(function (data) {
                    factory.getDocumentModal(data, self, preCloseModalFunction, archiveClassification, extra, undefined, undefined, undefined, documentType);
                }).catch(function (error) {
                    //Empty
            });
        }
        
		factory.openOneDocumentModalScan = function(preCloseModalFunction, self, archiveClassification, extra, documentType, disableDocumentType){
            factory.getDocumentFromScanner()
                .then(function (data) {
                    factory.getDocumentModal(data, self, preCloseModalFunction, archiveClassification, extra, undefined, undefined, undefined, documentType, undefined, true, disableDocumentType);
                }).catch(function (error) {
                    //Empty
            });
        }

        factory.openNewInteroperbleModal = function(self, dossierThirds, commitModal, docModule, transaction, interValues, selectIfOneThird, documentTypes, disableDocumentType){
        	factory.getNewInteroperableModal(self, dossierThirds, commitModal, docModule, transaction, interValues, selectIfOneThird, documentTypes, disableDocumentType);
        }

        factory.validDocumentName = function (title, type) {
            var daysInMonth = function (month, year) {
                return new Date(year, month, 0).getDate();
            }

            if($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value) {
                if(title) {
                    var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + type);

                    if (selectedType) {
						var patternProperties = HelperService.getPropertiesInPattern(); 
						if(patternProperties && patternProperties.actualDate){
							var pattern = HelperService.getFormattedPattern(selectedType.acronym, ((patternProperties && patternProperties.actualDate && patternProperties.actualDate.name)?patternProperties.actualDate.name:undefined), ((patternProperties && patternProperties.actualDate && patternProperties.actualDate.datePattern)?patternProperties.actualDate.datePattern:undefined));
							if(pattern){
								var pat = new RegExp(pattern);
								if(pat.test(title)){
									if(patternProperties && patternProperties.actualDate && patternProperties.actualDate.datePattern) {
			                            if(title.length >= patternProperties.actualDate.datePattern.length) {
			                                return HelperService.validateDatePattern(patternProperties.actualDate.name, patternProperties.actualDate.datePattern, title);
			                            } else {
			                                return false;
			                            }
			                        } else {
			                            return true;
			                        }
								} else {
		                        	return false;
		                    	}
		                    } else {
		                        return false;
		                    }
	                        
						}else{
							var pattern = HelperService.getFormattedPattern(selectedType.acronym, ((patternProperties && patternProperties.date && patternProperties.date.name)?patternProperties.date.name:undefined), ((patternProperties && patternProperties.date && patternProperties.date.datePattern)?patternProperties.date.datePattern:undefined));
	                        if(pattern){
								var pat = new RegExp(pattern);
								if(pat.test(title)){
									if(patternProperties && patternProperties.date && patternProperties.date.datePattern) {
			                            if(title.length >= patternProperties.date.datePattern.length) {
			                                return HelperService.validateDatePattern(patternProperties.date.name, patternProperties.date.datePattern, title);
			                            } else {
			                                return false;
			                            }
			                        } else {
			                            return true;
			                        }
								} else {
		                        	return false;
		                    	}
		                    } else {
		                        return false;
		                    }
						}
						
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return true
            }

        }
        
        factory.getNewInteroperableModal = function(self, thirds, commitModal, docModule, transaction, interValues, selectIfOneThird, documentTypes, disableDocumentType) {
        	var modalThirds = [];
            
            _.forEach(thirds ,function(third) {
            	if(third.third.thirdType == 'PHISICAL' || third.third.thirdType == 'LEGAL'){
            		modalThirds.push(third.third);
            	}
            });
			if(modalThirds.length) {
        		factory.getServicesVO()
		            .then(function (data) {
		            	var finishFromScannerWizard = function() {
		            		var self = this;
		            		var voModality = this.wizard.steps[1].annexaFormly.model;
		            		voModality.idService = this.wizard.steps[0].annexaFormly.model.idService;
			            	voModality.codeModality = this.wizard.steps[0].annexaFormly.model.modality;
			            	voModality.finalitats = [{code: this.wizard.steps[0].annexaFormly.model.finalitats}];
		            		var documentData = this.wizard.steps[2].annexaFormly.model.modal_body;
		            		_.forEach(voModality.voModalityDataList ,function(modalityData) {
			                	if(voModality[modalityData.packageUbication.replaceAll(".", "")]){
			                		modalityData.value = voModality[modalityData.packageUbication.replaceAll(".", "")];
			                		delete voModality[modalityData.packageUbication.replaceAll(".", "")];
			                	}
			                });
		            		var document = factory.getVODocumentValues(documentData, this.wizard.steps[2]);
		            		factory.getVODocument(voModality, document)
		            			.then(function (data) {
		            				self.close();
		            				DialogsFactory.notify('global.literals.newInteroperableOk');
		            				commitModal(data);
		            			}).catch(function (error) {
		            				var errorMessage = ErrorFactory.getErrorMessage('documents', 'voDocument', error.data)
		            				if((errorMessage == null || errorMessage == undefined) && error.data.message) {
		            					errorMessage = error.data.message;
		            				}
		            				DialogsFactory.error(errorMessage, $filter('translate')('DIALOGS_ERROR_MSG'));
		        	        });
		                }
		            	var modal = angular.copy(globalModals.newInteroperableWizardModal);
		            	modal.alerts = [];
		            	modal.wizard.modal = modal;
		                modal.documentFactory = factory;
		                
		                modal.wizard.steps[0].annexaFormly.fields[0].templateOptions.options = modalThirds;
						var onlyOne = false;
						if(selectIfOneThird && modalThirds && modalThirds.length === 1){
							if(!modal.wizard.steps[0].annexaFormly.model){
								modal.wizard.steps[0].annexaFormly.model = {};
							}
							modal.wizard.steps[0].annexaFormly.model.third = ((modalThirds[0] && modalThirds[0].id)?modalThirds[0].id:undefined);
							modal.wizard.steps[0].annexaFormly.model.selectedThird = ((modalThirds[0] && modalThirds[0].id)?modalThirds[0]:undefined);
							onlyOne = true;				
						}
        	            modal.wizard.steps[0].annexaFormly.fields[1].templateOptions.options = data;
						if(interValues){
							var documentationIdService = ((interValues.procedureDocumentationProvide && interValues.procedureDocumentationProvide.idService)?interValues.procedureDocumentationProvide.idService:((interValues.archiveClassificationDocumentationProvide && interValues.archiveClassificationDocumentationProvide.idService)?interValues.archiveClassificationDocumentationProvide.idService:undefined));
							var documentationModality = ((interValues.procedureDocumentationProvide && interValues.procedureDocumentationProvide.modality)?interValues.procedureDocumentationProvide.modality:((interValues.archiveClassificationDocumentationProvide && interValues.archiveClassificationDocumentationProvide.modality)?interValues.archiveClassificationDocumentationProvide.modality:undefined));
							var documentationPurpose = ((interValues.procedureDocumentationProvide && interValues.procedureDocumentationProvide.purpose)?interValues.procedureDocumentationProvide.purpose:((interValues.archiveClassificationDocumentationProvide && interValues.archiveClassificationDocumentationProvide.purpose)?interValues.archiveClassificationDocumentationProvide.purpose:undefined));
							if(documentationIdService){
								var serv = $linq(data).firstOrDefault({name: undefined, voModalityList:[]}, "x => x.id == "+documentationIdService);
								if(serv){
									if(!modal.wizard.steps[0].annexaFormly.model){
										modal.wizard.steps[0].annexaFormly.model = {};
									}
									modal.wizard.steps[0].annexaFormly.model.serviceLabel = serv.name;
									modal.wizard.steps[0].annexaFormly.model.service = serv.codeService;
									modal.wizard.steps[0].annexaFormly.model.idService = serv.id;
									var servField = $linq(modal.wizard.steps[0].annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'service'");
									if(servField && servField.templateOptions){
										servField.templateOptions.disabled = ((onlyOne)?true:false);
									}
									modal.wizard.steps[0].annexaFormly.fields[2].templateOptions.options = serv.voModalityList;
									if(serv.voModalityList && serv.voModalityList.length > 0 && documentationModality){
										var modality = $linq(serv.voModalityList).firstOrDefault({name: undefined, finalitats:[]}, "x => x.codeModality == '"+documentationModality+"'");
										if(modality){
											var modField = $linq(modal.wizard.steps[0].annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'modality'");
											if(modField && modField.templateOptions){
												modField.templateOptions.disabled = ((onlyOne)?true:false);
											}
											modal.wizard.steps[0].annexaFormly.model.modality = modality.codeModality;
											modal.wizard.steps[0].annexaFormly.model.modalityLabel = modality.name;
											modal.wizard.steps[0].annexaFormly.fields[3].templateOptions.options = modality.finalitats;
											if(modality.finalitats && modality.finalitats.length > 0 && documentationPurpose){
												var purpose = $linq(modality.finalitats).firstOrDefault({name: undefined}, "x => x.code == '"+documentationPurpose+"'");
												if(purpose){
													var modField = $linq(modal.wizard.steps[0].annexaFormly.fields).firstOrDefault(undefined,"x => x.key == 'finalitats'");
													if(modField && modField.templateOptions){
														modField.templateOptions.disabled = ((onlyOne)?true:false);
													}
													modal.wizard.steps[0].annexaFormly.model.finalitats = purpose.code;
													modal.wizard.steps[0].annexaFormly.model.finalitatsLabel = purpose.name;
												}
											}
										} 
									}
								}
							}
						}
		                
		                modal.wizard.steps[2].annexaFormly.model = {};
		                modal.wizard.steps[2].annexaFormly.model['modal_body'] = {
		                		module: docModule,
		                		extra: transaction,
		                		archiveClassification: HelperService.treeSelected(self.archiveClassification) ? self.archiveClassification.model : undefined,
		                		modal_body_key: ((modal.wizard.steps[2].annexaFormly.fields && modal.wizard.steps[2].annexaFormly.fields.length > 0)?modal.wizard.steps[2].annexaFormly.fields[0].key:undefined)
		                };
						var types = GlobalDataFactory.documentTypes; 
						if(documentTypes && documentTypes.length > 0){
							types = documentTypes;
							if(types.length == 1){
								modal.wizard.steps[2].annexaFormly.model['modal_body'].typeSelect = types[0].id;
							}
							if(modal.wizard.steps[2] && modal.wizard.steps[2].annexaFormly && modal.wizard.steps[2].annexaFormly.fields && modal.wizard.steps[2].annexaFormly.fields.length > 0 && modal.wizard.steps[2].annexaFormly.fields[0].fieldGroup){
								var docTypeField = $linq(modal.wizard.steps[2].annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
					            if(docTypeField){
									docTypeField.templateOptions.optionsAux = types;
									docTypeField.templateOptions.disabled = ((disableDocumentType)?true:false);
									docTypeField.templateOptions.modal_body_key = modal.wizard.steps[2].annexaFormly.fields[0].key;
								}
							}
						}else{
							if(modal.wizard.steps[2] && modal.wizard.steps[2].annexaFormly && modal.wizard.steps[2].annexaFormly.fields && modal.wizard.steps[2].annexaFormly.fields.length > 0 && modal.wizard.steps[2].annexaFormly.fields[0].fieldGroup){
								var docTypeField = $linq(modal.wizard.steps[2].annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
					            if(docTypeField){
									docTypeField.templateOptions.modal_body_key = modal.wizard.steps[2].annexaFormly.fields[0].key;
								}
							}
						}
						if(modal.wizard.steps[2].annexaFormly.model['modal_body'].typeSelect && modal.wizard.steps[2].annexaFormly.fields && modal.wizard.steps[2].annexaFormly.fields.length > 0){
							factory.modifyCustomFields(modal.wizard.steps[2].annexaFormly.model['modal_body'].typeSelect, modal.wizard.steps[2].annexaFormly, modal.wizard.steps[2].annexaFormly.fields[0].key);
						}
		                AnnexaFormlyFactory.showModal('newInteroperableWizardModal', modal, finishFromScannerWizard, false, true)
		            }).catch(function (error) {
		            	DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'voDocument', error.data), $filter('translate')('DIALOGS_ERROR_MSG'));
		        });
        	} else {
        		DialogsFactory.error($filter('translate')('global.errors.voDocumentNoThirds'), $filter('translate')('DIALOGS_ERROR_MSG'));
        	}
        }
        
		factory.openMassiveInteroperableModal = function(globals, thirds, module, dossier, tramNewFactory) {
			var voModalitiesToSearch = [];
			var globalsInteroperable = [];
			var thirdsInteroperable = [];
			var commitModal = function(){
				var selfMassiveInter = this;
				if(this && this.wizard && this.wizard.steps){
					var documents = [];
					_.forEach(this.wizard.steps, function(step){
						if(step && step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.voModality){
		            		var voModality = angular.copy(step.annexaFormly.model.voModality);
							var documentationToProvide = angular.copy(step.annexaFormly.model.documentationToProvide);
							var thirdsel = undefined;
		            		if(documentationToProvide && documentationToProvide.document){
								voModality.idService = ((documentationToProvide.document.procedureDocumentationProvide)?documentationToProvide.document.procedureDocumentationProvide.idService:((documentationToProvide.document.archiveClassificationDocumentationProvide)?documentationToProvide.document.archiveClassificationDocumentationProvide.idService:undefined));
				            	voModality.codeModality = ((documentationToProvide.document.procedureDocumentationProvide)?documentationToProvide.document.procedureDocumentationProvide.modality:((documentationToProvide.document.archiveClassificationDocumentationProvide)?documentationToProvide.document.archiveClassificationDocumentationProvide.modality:undefined));
				            	voModality.finalitats = ((documentationToProvide.document.procedureDocumentationProvide)?[{code: documentationToProvide.document.procedureDocumentationProvide.purpose}]:((documentationToProvide.document.archiveClassificationDocumentationProvide)?[{code: documentationToProvide.document.archiveClassificationDocumentationProvide.purpose}]:undefined));
							}else{
								voModality.idService = ((documentationToProvide && documentationToProvide.procedureDocumentationProvide)?documentationToProvide.procedureDocumentationProvide.idService:((documentationToProvide && documentationToProvide.archiveClassificationDocumentationProvide)?documentationToProvide.archiveClassificationDocumentationProvide.idService:undefined));
				            	voModality.codeModality = ((documentationToProvide && documentationToProvide.procedureDocumentationProvide)?documentationToProvide.procedureDocumentationProvide.modality:((documentationToProvide && documentationToProvide.archiveClassificationDocumentationProvide)?documentationToProvide.archiveClassificationDocumentationProvide.modality:undefined));
				            	voModality.finalitats = ((documentationToProvide && documentationToProvide.procedureDocumentationProvide)?[{code: documentationToProvide.procedureDocumentationProvide.purpose}]:((documentationToProvide && documentationToProvide.archiveClassificationDocumentationProvide)?[{code: documentationToProvide.archiveClassificationDocumentationProvide.purpose}]:undefined));
							}
							if(step.annexaFormly.model.thirdPropertiesToAdd && step.annexaFormly.model.thirdPropertiesToAdd.thirds){
								var thirdField = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x=> x.key == 'thirdPropertiesToAdd'");
								if(thirdField && thirdField.fieldGroup && thirdField.fieldGroup.length > 0 && thirdField.fieldGroup[0] && thirdField.fieldGroup[0].templateOptions && thirdField.fieldGroup[0].templateOptions.options){
									thirdsel = $linq(thirdField.fieldGroup[0].templateOptions.options).firstOrDefault(undefined, "x => x.id == "+step.annexaFormly.model.thirdPropertiesToAdd.thirds);
									if(thirdsel){
										voModality.voGenericData = {
											citizenFullName:thirdsel.completeName,
			                    			citizenNIF:thirdsel.identificationDocument,
			                    			citizenNIFType:thirdsel.identificationDocumentType,
			                    			citizenName:thirdsel.name,
			                    			citizenSurname:((thirdsel.surname1Particles)?thirdsel.surname1Particles+' ':'')+thirdsel.surename1,
			                    			citizenSecondSurname:((thirdsel.surname2Particles)?thirdsel.surname2Particles+' ':'')+thirdsel.surename2,
										};
									}
								}
							}else if(documentationToProvide && documentationToProvide.third && documentationToProvide.third.third){
								voModality.voGenericData = {
									citizenFullName:documentationToProvide.third.third.completeName,
	                    			citizenNIF:documentationToProvide.third.third.identificationDocument,
	                    			citizenNIFType:documentationToProvide.third.third.identificationDocumentType,
	                    			citizenName:documentationToProvide.third.third.name,
	                    			citizenSurname:((documentationToProvide.third.third.surname1Particles)?documentationToProvide.third.third.surname1Particles+' ':'')+documentationToProvide.third.third.surename1,
	                    			citizenSecondSurname:((documentationToProvide.third.third.surname2Particles)?documentationToProvide.third.third.surname2Particles+' ':'')+documentationToProvide.third.third.surename2,
								};
							}
		            		var documentData = step.annexaFormly.model.documentPropertiesToAdd;
		            		_.forEach(voModality.voModalityDataList ,function(modalityData) {
			                	if(step.annexaFormly.model[modalityData.packageUbication.replaceAll(".", "")]){
			                		modalityData.value = step.annexaFormly.model[modalityData.packageUbication.replaceAll(".", "")];
			                	}
			                });
		            		var document = factory.getVODocumentValues(documentData, step);
							if(documentationToProvide && documentationToProvide.document){
								documentationToProvide.document.dossierTransaction = ((documentData && documentData.dossierTransactionToAdded && documentData.dossierTransactionToAdded.id)?documentData.dossierTransactionToAdded.id:undefined);
								documentationToProvide.document.dossierThird = ((documentationToProvide.third && documentationToProvide.third.third && documentationToProvide.third.third.id)?documentationToProvide.third.third.id:undefined);
 							}else{
								documentationToProvide.dossierTransaction = ((documentData && documentData.dossierTransactionToAdded && documentData.dossierTransactionToAdded.id)?documentData.dossierTransactionToAdded.id:undefined);
								documentationToProvide.dossierThird = ((thirdsel && thirdsel.id)?thirdsel.id:undefined);
							}
							documents.push({voModality:voModality, documentData:document, user:{id:$rootScope.LoggedUser.id}, documentationToProvide:((documentationToProvide && documentationToProvide.document)?documentationToProvide && documentationToProvide.document:documentationToProvide && documentationToProvide)});
						}
					});
					if(documents && documents.length > 0){
						factory.getVODocuments(documents).then(function (data) {
            				if(data && data.errors){
								var keys = Object.keys(data.errors);
								var error = '';
								if(keys && keys.length > 0){
									var getTranslatedError = function(errorMessage){
										var errorAux = '';
										if(errorMessage){
											if(errorMessage == 'No dtp to update') {
					                            errorAux = 'global.tram.literals.notExistDocumentation'
					                        }else if(errorMessage == 'Document is required') {
					                            errorAux = 'global.tram.literals.documentIsRequiredInDocumentation'
					                        }else if(errorMessage == 'User cannot change status') {
					                            errorAux = 'global.tram.literals.userNotHavePermission'
					                        }else if(errorMessage == 'Dossier third is required') {
					                            errorAux = 'global.tram.literals.errorUpdateStatusDocumentation'
					                        }else if(errorMessage == 'Dossier transaction is required') {
					                            errorAux = 'global.tram.literals.errorUpdateStatusDocumentation'
					                        }else if(errorMessage == 'No dossier transaction to associate') {
					                            errorAux = 'global.tram.literals.errorUpdateStatusDocumentation'
					                        }else if(errorMessage == 'No documents to create') {
					                            errorAux = 'global.tram.literals.errorUpdateStatusDocumentation'
					                        }else if(errorMessage == 'No services recived') {
					                            errorAux = 'global.errors.voDocumentErrorNoServicesRecived';
					                        }else if(errorMessage == 'Error reading response') {
					                            errorAux = 'global.errors.voDocumentErrorReadingServices';
					                        }else if(errorMessage == 'No modality data recived') {
					                            errorAux = 'global.errors.voDocumentErrorNoModalityDataRecived';
					                        }else if(errorMessage == 'Error read json modality') {
					                            errorAux = 'global.errors.voDocumentErrorJsonModality';
					                        }else if(errorMessage == 'Error connecting VO') {
					                            errorAux = 'global.errors.voDocumentErrorConnectingVO';
					                        }else if(errorMessage == 'No document recived') {
					                            errorAux = 'global.errors.voDocumentErrorNoDocumentRecived';
					                        }else if(errorMessage == 'Error read json document') {
					                            errorAux = 'global.errors.voDocumentErrorJsonDocument';
					                        }else if(errorMessage == 'No PDF recived') {
					                            errorAux = 'global.errors.voDocumentErrorNoPdfDocumentRecived';
					                        }else if(errorMessage == 'No XML recived') {
					                            errorAux = 'global.errors.voDocumentErrorNoXmlDocumentRecived';
					                        }else if(errorMessage == 'Error adding documents') {
					                            errorAux = 'global.errors.voDocumentErrorAddingDocuments';
					                        }else if(errorMessage == 'No message available') {
					                            errorAux = 'global.errors.voDocumentUnknownError';
											}else{
												errorAux = errorMessage;
					                    	}
										}
										return ((errorAux)?' '+$filter('translate')('DIALOGS_ERROR')+': '+$filter('translate')(errorAux):'');
									}
									error = $filter('translate')('global.errors.massiveErrorMeessage')+':</br>';
									_.forEach(keys, function(key){
										if(key === 'No_documents_to_create' || isNaN(key)){
											error += '&nbsp;&nbsp;&nbsp;&nbsp;'+ getTranslatedError(data.errors[key])+'</br>';
										}else{
											var num = Number(key);
											if(document && documents.length > num){
												var doc = documents[num];
												if(doc && doc.documentationToProvide){
													if(doc.documentationToProvide.scopeApplication === 'THIRD'){
														error += '&nbsp;&nbsp;&nbsp;&nbsp;';
														error += ' '+((doc.documentationToProvide.documentType)?doc.documentationToProvide.documentType[Language.getActiveColumn()]:'');
														error += ' - '+((doc.documentationToProvide.third)?doc.documentationToProvide.third.completeName:'');
														error += ' - '+((doc.documentationToProvide.description)?doc.documentationToProvide.description:'')+'</br>';
														error += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+getTranslatedError(data.errors[key])+'</br>';
													}else{
														error += '&nbsp;&nbsp;&nbsp;&nbsp;';
														error += ' '+((doc.documentationToProvide.documentType)?doc.documentationToProvide.documentType[Language.getActiveColumn()]:'');
														error += ' - '+((doc.documentationToProvide.scopeApplication)?doc.documentationToProvide.scopeApplication:'');
														error += ' - '+((doc.documentationToProvide.description)?doc.documentationToProvide.description:'')+'</br>';
														error += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+getTranslatedError(data.errors[key])+'</br>';
													}
												}else{
													error += '&nbsp;&nbsp;&nbsp;&nbsp;'+ getTranslatedError(data.errors[key], true)+'</br>';
												}
											}else{
												error += '&nbsp;&nbsp;&nbsp;&nbsp;'+ getTranslatedError(data.errors[key], true)+'</br>';
											}
										}
									});
									DialogsFactory.error(error, $filter('translate')('DIALOGS_ERROR_MSG'));
								}else{
									DialogsFactory.notify('global.literals.newInteroperableOk');	
								}
							}else{
								DialogsFactory.notify('global.literals.newInteroperableOk');	
							}
							if(data && data.dtpr){
								$rootScope.$broadcast('annexaBoxObjectDossierDocumentationToProvideMassiveUpdated', { dtpr:data.dtpr });
							}
            				selfMassiveInter.close();
            			}).catch(function (error) {
            				DialogsFactory.error($filter('translate')('global.tram.literals.errorUpdateStatusDocumentation'), $filter('translate')('DIALOGS_ERROR_MSG'));
	        	        });
					}else{
		            	DialogsFactory.error($filter('translate')('global.errors.noDocumentsToCreate'), $filter('translate')('DIALOGS_ERROR_MSG'));						
					}
				}else{
		            DialogsFactory.error($filter('translate')('global.errors.noDocumentsToCreate'), $filter('translate')('DIALOGS_ERROR_MSG'));
				}				
			}
			if(globals && globals.length > 0){
				_.forEach(globals, function(global){
					if(global && global.interoperability && global.status === 'PENDING'){
						var documentationIdService = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.idService)?global.procedureDocumentationProvide.idService:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.idService)?global.archiveClassificationDocumentationProvide.idService:undefined));
						var documentationModality = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.modality)?global.procedureDocumentationProvide.modality:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.modality)?global.archiveClassificationDocumentationProvide.modality:undefined));
						var documentationService = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.service)?global.procedureDocumentationProvide.service:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.service)?global.archiveClassificationDocumentationProvide.service:undefined));
						if(documentationIdService && documentationModality && documentationService){
							voModalitiesToSearch.push({idService:documentationIdService, service:documentationService, modality:documentationModality});
							globalsInteroperable.push(global);
						}
					}
				});
			}
			if(thirds && thirds.length > 0){
				_.forEach(thirds, function(third){
					if(third && third.documents){
						_.forEach(third.documents, function(global){
							if(global && global.interoperability && global.status === 'PENDING'){
								var documentationIdService = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.idService)?global.procedureDocumentationProvide.idService:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.idService)?global.archiveClassificationDocumentationProvide.idService:undefined));
								var documentationModality = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.modality)?global.procedureDocumentationProvide.modality:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.modality)?global.archiveClassificationDocumentationProvide.modality:undefined));
								var documentationService = ((global.procedureDocumentationProvide && global.procedureDocumentationProvide.service)?global.procedureDocumentationProvide.service:((global.archiveClassificationDocumentationProvide && global.archiveClassificationDocumentationProvide.service)?global.archiveClassificationDocumentationProvide.service:undefined));
								if(documentationIdService && documentationModality && documentationService){
									voModalitiesToSearch.push({idService:documentationIdService, service:documentationService, modality:documentationModality});
									thirdsInteroperable.push({document:global, third:third});
								}
							}
						});	
					}
				});
			}
			if(voModalitiesToSearch && voModalitiesToSearch.length > 0){
				var createStep = function(key){
					var step = {
	                    title: '',
	                    key: key,
	                    disabled: false,
	                    disableNext: function(model, wizard) {
	                    	try{
	                    		var key = this.key;
	                    		var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == key); });
	    	                    if (indexNextStep != undefined && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
		                    		if(model && model.documentPropertiesToAdd && model.documentPropertiesToAdd.typeSelect && !model.documentPropertiesToAdd.origDocumentType){
		                    			if(model.documentPropertiesToAdd.modal_body_key && this.key && model.documentPropertiesToAdd.modal_body_key === this.key  && !model.callToModifyCustomFields){
		                    				model.callToModifyCustomFields = true;
	                                    	factory.modifyCustomFields(model.documentPropertiesToAdd.typeSelect, wizard.steps[indexNextStep].annexaFormly, 'documentPropertiesToAdd', 'documentPropertiesToAdd');
	                                    }
		                    		}
	    	                    }
	                    	}catch(e){
	                    		 console.log(e);
	                    	}
	                        var selected = false;
							var step = $linq(wizard.steps).firstOrDefault(undefined, "x => x.key == '"+key+"'")
	                        if(step && step.annexaFormly.form.$valid && !model.hasError){
	                            selected = true;
	                        }
	                        return !selected;
	                    },
	                    annexaFormly: {
	                        fields: [
	                        ],
							model:{ thirdPropertiesToAdd:{}, documentPropertiesToAdd:{}}
	                    }
	                }
					return step;
				}
				var createThirdField = function(modalThirds){
					var thirdField = {
				        key: 'thirdPropertiesToAdd',
						className: 'col-sm-12',
				        fieldGroup: [{
	                        key: 'thirds',
	                        type: 'annexaRadioCheckboxRow',
	                        className: 'col-sm-12 daughter-label-strong',
	                        data: {
	                            informed: true,
	                            row: true
	                        },
	                        templateOptions: {
	                            type: 'radio',
	                            optionsAttr: 'bs-options',
	                            required: true,
	                            validate: true,
	                            options:modalThirds,
	                            ngOptions:'option[to.valueProp] as option in to.options',
	                            valueProp: 'id',
	                            labelProp: 'completeName',
	                            label: 'global.literals.receiver'
	                        },
	                        watcher: {
	                            type: '$watchCollection',
	                            expression: 'model.third',
	                            listener: function (field, _new, _old, scope) {
	                            	_.forEach(field.templateOptions.options ,function(option) {
	                            		var CommonService = angular.element(document.body).injector().get('CommonService');
	            	                	if(_new == option.id) {
	            	                		scope.model.selectedThird = option;
	            	                	}
	            	                	if(option.completeName == undefined) {
	            	                		option.completeName = CommonService.getThirdCompleteName(option);
	            	                	}
	            	                });
	                            }
	                        }
	                	}],
				        wrapper: 'annexaRow'
				    };
					return thirdField;
				}
				
				var addDocumentFields = function(step, ti, docModule, dossier){
					if(step && step.annexaFormly && step.annexaFormly.fields){
						var group = {
					        key: 'documentPropertiesToAdd',
					        className: 'col-sm-12',
				        	fieldGroup: [], 
				        	wrapper: 'annexaRow'
						};
						step.annexaFormly.fields.push(group);
						var dossierTransactions = [];
						
						group.fieldGroup.push({
        					key: '',
                            type: 'annexaLabel',
                            templateOptions: {
                                label: '',
                                value:'',
                                labelClass: 'p-x-0 _700 text'
                            },
                            data: {
                                informed: true,
                                row: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller: function ($scope, $filter) {
                                $scope.to.label = $filter("translate")("global.literals.newInteroperableDocumentValues");
                            }
        	        	});
						if(dossier && dossier.dossierTransactions){
							dossierTransactions = $linq(dossier.dossierTransactions).where("x => x.actual").toArray();
							if(dossierTransactions && dossierTransactions.length > 0){
								step.annexaFormly.model.documentPropertiesToAdd.dossierTransactionToAdded = dossierTransactions[0];	
							}
						}
						group.fieldGroup.push({
                            key: 'dossierTransactionToAdded',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            data: {
                                informed:true,
                                row:true,
                                clear: function($event,model,key, $select) {
                                    $event.stopPropagation();
                                    model[key] = undefined;
                                    if($select) {
                                        $select.selected = undefined;
                                        $select.search = undefined;
                                    }
                                }
                            },
                            templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option in to.options | filter: $select.search',
                                label: 'global.tram.literals.selectDossierTransactionToAddDocument',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: dossierTransactions,
                                required: true,
                                focus: false,
								onSelected: function(item, options) {
                                	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    $rootScope.$broadcast('newDossierTransactionToAddedSelected'+step.key, { item: item });
                                }
                            },
                            controller:['$scope', 'Language', function($scope, Language) {
                                $scope.to.labelProp = Language.getActiveColumn();
                            }]
                        });
						group.fieldGroup.push({
	                        key: 'typeSelect',
	                        type: 'annexaSelectRow',
	                        className: 'col-sm-12',
							templateOptions: {
	                            optionsAttr: 'bs-options',
	                            ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
	                            label: 'global.literals.type',
	                            valueProp: 'id',
	                            labelProp: 'language1',
	                            placeholder: '',
	                            options: [],
	                            required: true,
	                            clearHide: true,
                                onSelected: function(item, options) {
                                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                    $rootScope.$broadcast('newDocumentTypeInteroperableMassiveSelected', { item: item, modal_body_key: ((options && options.templateOptions && options.templateOptions.modal_body_key)?options.templateOptions.modal_body_key:undefined)});
                                }
	                        },
	                        controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
	                            $scope.to.labelProp = Language.getActiveColumn();
	                            $scope.to.options = [];
	                            var types = GlobalDataFactory.documentTypes;
	                            if($scope.to.optionsAux && $scope.to.optionsAux.length > 0){
	                            	types = $scope.to.optionsAux;
	                            }
	                            _.forEach (types, function(docType){
	                            	var canUseType = true;
	                            	if($scope.originalModel && $scope.originalModel.module && $scope.originalModel.module == 'REGSALIDA'
	                            		&& !docType.includeRegisterEntry){
	                            		canUseType = false;
	                            	}
	                                if(docType.profiles && docType.profiles.length > 0){
	                                    // intersection de perfil de usuario y los perfiles de documentTypeProfile
	                                    var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
	                                        if(y.profile){
	                                            return (x.profile.id == y.profile.id);
	                                        }
	                                        // en este caso es por si hubiera un null, ya que admitimos todos los perfiles.
	                                        else{
	                                            return true;
	                                        }
	                                    }).toArray();
	                                    if(profiles && profiles.length > 0 && canUseType){
	                                        $scope.to.options.push(angular.copy(docType));
	                                    }
	                                    else{
	                                    }
	                                }
	                                else if(canUseType){
	                                    $scope.to.options.push(angular.copy(docType));
	                                }
	                            });
	                            $scope.$on('newDocumentTypeInteroperableMassiveSelected', function(event, args) {
                                    if(args.modal_body_key && $scope.model && $scope.model.modal_body_key && args.modal_body_key === $scope.model.modal_body_key){
                                    	DccumentsFactory.modifyCustomFields(((args.item)?args.item.id:undefined), $scope);
                                    }
                                });
	                        }],
	                        data: {
	                            row: true,
	                            informed: true,
	                            colClass: ' col-sm-12',
	                            labelClass: 'label-strong'
	                        }
	                    });
						group.fieldGroup.push({
	                        key: 'title',
	                        type: 'annexaInputRow',
	                        className: 'col-sm-12',
							data: {
	                            row: true,
	                            colClass: ' col-sm-12',
	                            labelClass: 'label-strong',
	                            informed: true,
	                            clear: function ($event, model, key, $select) {
	                                $event.stopPropagation();
	                                model[key] = undefined;
	                                if ($select) {
	                                    $select.selected = undefined;
	                                    $select.search = undefined;
	                                }
	                            }
	                        },
	                        templateOptions: {
	                            type: 'text',
	                            label: 'global.literals.title',
	                            required: true,
	                            focus: false,
	                            placeholder:'',
	                            updateOnBlur: true
	                        },
	                        controller: ['$scope', 'DccumentsFactory', 'Language', '$rootScope', 'GlobalDataFactory', 'HelperService', function($scope, DccumentsFactory, Language, $rootScope, GlobalDataFactory, HelperService) {
	                            var titleAux = '';
	                            if($scope.model.typeSelect) {
	                                var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);
	
	                                if(selectedType) {
	                                    $scope.to.placeholder = selectedType[Language.getActiveColumn()];
	                                } else {
	                                    $scope.to.placeholder = '';
	                                }
	                            } else {
	                                $scope.to.placeholder = '';
	                            }
	
	                            $scope.to.onFocus = function(viewValue){
	                                if($rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
	                                    if($scope.model.typeSelect) {
	                                        var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);
	                                        if(selectedType) {  
	                              				var patternProperties = HelperService.getPropertiesInPattern(); 
	                                        	if ($scope.lastSelectedType) {
	                                        		//Ya hay un tipo de documento seleccionado
	                                        		var pos = $scope.model.title.indexOf($scope.lastSelectedType);
	                                        		if (pos >= 0) {
	                                        			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
	                                        			$scope.model.title = $scope.model.title.replace($scope.lastSelectedType, selectedType.acronym);
	                                        		} else {
	                                        			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
														if(patternProperties && patternProperties.acronym){
															if(!patternProperties.name || (patternProperties.name && patternProperties.name.pos >= 0 && patternProperties.acronym.pos >= 0 && patternProperties.name.pos > patternProperties.acronym.pos)){
																$scope.model.title = selectedType.acronym + ' ' + $scope.model.title;		
															}else{
																$scope.model.title = $scope.model.title + ' ' + selectedType.acronym;
															}
														}
	                                        		}                                                		                                                		
	                                        	} else {
	                                        		//No hay un tipo de documento seleccionado
	                                        		if (viewValue) {
														if(patternProperties && patternProperties.acronym){
															if(!patternProperties.name || (patternProperties.name && patternProperties.name.pos >= 0 && patternProperties.acronym.pos >= 0 && patternProperties.name.pos > patternProperties.acronym.pos)){
																var pos = viewValue.indexOf(selectedType.acronym + ' ');
				                            					if (pos >= 0) {
																	$scope.model.title = viewValue;
				                            					}else{
																	$scope.model.title = selectedType.acronym + ' ' + viewValue;
																}
															}else{
																var pos = viewValue.indexOf(' '+selectedType.acronym);
				                            					if (pos >= 0) {
																	$scope.model.title = viewValue;
				                            					}else{
																	$scope.model.title = viewValue + ' ' + selectedType.acronym;
																}
															}
														}else{
															$scope.model.title = viewValue;
														}
	                                        		} else {
	                                        			if(patternProperties && patternProperties.acronym){
															if(!patternProperties.name || (patternProperties.name && patternProperties.name.pos >= 0 && patternProperties.acronym.pos >= 0 && patternProperties.name.pos > patternProperties.acronym.pos)){
																$scope.model.title = selectedType.acronym + ' ';
															}else{
																$scope.model.title = ' '+selectedType.acronym;
															}
														}else{
															$scope.model.title = ' ';
														}
	                                                }
	                                        	}                                                	
	                                        	titleAux = angular.copy($scope.model.title);
	                                        	$scope.lastSelectedType = ((patternProperties && patternProperties.acronym)?selectedType.acronym:'');
	                                        }                                                
	                                    }
	                                }
	                            };
	
	                        }]
	                    });
						group.fieldGroup.push({
                            key: 'archiveClassification',
                            type: 'annexaSelectTreeRow',
                            className: 'col-sm-12',
							templateOptions: {
                                label: 'global.literals.classificationBox',
                                options: [],
                                required: true,
                                disabled: true,
                                focus: false
                            },
                            controller:['$scope', 'CommonService', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, CommonService, DccumentsFactory, Language, GlobalDataFactory) {
                                $scope.to.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                                $scope.to.labelProp = Language.getActiveColumn();
                                $scope.to.onSelected = function(index) {
                                    $scope.model.modifyArchiveClassification = true;
                                    $scope.model.archiveClassification = index.id;
                                };
                                if(!$scope.model.archiveClassification) {
                                	$scope.to.disabled = false;
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            watcher:{
                                type: '$watchCollection',
                                expression: 'model.typeSelect',
                                listener: function(field, _new, aux,scope) {
                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                    DccumentsFactory.setFileArchiveClassification(scope.model);
                                }
                            }
                        });
						group.fieldGroup.push({
	                        key: 'section',
	                        type: 'annexaSelectRow',
	                        className: 'col-sm-12',
							templateOptions: {
	                            optionsAttr: 'bs-options',
	                            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
	                            label: 'global.literals.section',
	                            valueProp: 'id',
	                            labelProp: 'language1',
	                            placeholder: '',
	                            options: [],
	                            required: true,
	                            clearHide: true
	                        },
	                        controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', function($scope, Language, GlobalDataFactory, DccumentsFactory) {
	                            $scope.to.options = DccumentsFactory.sectionsUser;
	                            $scope.to.labelProp = Language.getActiveColumn();
	                        }],
	                        data: {
	                            row: true,
	                            informed: true,
	                            colClass: ' col-sm-12',
	                            labelClass: 'label-strong'
	                        },
	                        hideExpression: function ($viewValue, $modelValue, scope) {
	                            return scope.model.oneSection ? true : false;
	                        }
	                    });
						group.fieldGroup.push({
                            key: 'profiles',
                            type: 'annexaMultipleSelectRow',
                            className: 'col-sm-12',
							templateOptions: {
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                label: 'global.literals.profiles',
                                valueProp: 'id',
                                labelProp: 'completeName',
                                placeholder: '',
                                options: [],
                                required: true
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller:['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', 'HelperService', function($scope, Language, GlobalDataFactory, DccumentsFactory, HelperService) {
                                $scope.to.labelProp = Language.getActiveColumn();
								var addprofiles = function(){
									if($scope.model.module != 'EXPED'){
										$scope.to.options = DccumentsFactory.profilesUser;
									}else{
										if($scope.model.dossierTransactionToAdded && $scope.model.dossierTransactionToAdded.processProfiles){
											$scope.to.options = $linq(DccumentsFactory.profilesUser).intersect($linq($scope.model.dossierTransactionToAdded.processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();
										}else{
											$scope.to.options = [];
										}
									}
	                                $scope.to.options = $linq($scope.to.options).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	                                if($scope.to.options && $scope.to.options.length == 1){
	                                    if($scope.model.profiles){
	                                        $scope.model.profiles.push($scope.to.options[0].id);
	                                    }
	                                    else{
	                                        $scope.model.profiles = [];
	                                        $scope.model.profiles.push($scope.to.options[0].id);
	                                    }
	                                }else if($scope.model.module == 'EXPED'){
	                                    if(!$scope.model.profiles){
	                                        $scope.model.profiles = [];
	                                    }else{
	                                        $scope.model.profiles.length = 0;
	                                    }
	                                }
								}
								addprofiles();
								$scope.$on('newDossierTransactionToAddedSelected'+step.key, function(event, args) {
									addprofiles();
						        });
								
                            }],
                            hideExpression: function ($viewValue, $modelValue, scope) {
                                return scope.model.oneProfile ? true : false;
                            }

                        });
						group.fieldGroup.push({
                        	key: 'documentCustomFields',
                        	className: 'col-sm-12',
                        	data: {
	                            row: true,
	                            colClass: ' col-sm-12',
	                            labelClass: 'label-strong',
	                            informed: true
	                        },
                        	templateOptions: {},
                        	fieldGroup: [],
                            hideExpression: function ($viewValue, $modelValue, scope) {
                            	if(scope.model && scope.model.documentCustomFieldsShow){
                            		return false;
                            	}else{
                            		return true;
                            	}
                            }, 
				        	wrapper: 'annexaRow'
                        });
						if(ti && ti.documentType && ti.documentType.id){ 
				            if(!step.annexaFormly.model){
								step.annexaFormly.model = { thirdPropertiesToAdd:{}, documentPropertiesToAdd:{documentCustomFields: {},origDocumentType:undefined,modal_body_key: step.key}};
							}else{
								if(step.annexaFormly.model.documentPropertiesToAdd){
									step.annexaFormly.model.documentPropertiesToAdd.documentCustomFields = {};
									step.annexaFormly.model.documentPropertiesToAdd.origDocumentType = undefined;
									step.annexaFormly.model.documentPropertiesToAdd.modal_body_key = step.key;
								}else{
									step.annexaFormly.model.documentPropertiesToAdd = {documentCustomFields: {},origDocumentType:undefined,modal_body_key: step.key};
								}
							}
				            step.annexaFormly.model.documentPropertiesToAdd.module = docModule;
							var ar = ((dossier && dossier.archiveClassification)?angular.copy(dossier.archiveClassification):undefined);
							step.annexaFormly.model.documentPropertiesToAdd.archiveClassification = ((HelperService.treeSelected(ar))?ar.model:undefined);
							var documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+ti.documentType.id);
							var docTypeField = $linq(group.fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
							if(documentType){
								step.annexaFormly.model.documentPropertiesToAdd.typeSelect = documentType.id;
								if(docTypeField){
									docTypeField.templateOptions.optionsAux = [documentType];
									docTypeField.templateOptions.disabled = true;
								}
							}
							if(docTypeField){
								docTypeField.templateOptions.modal_body_key = step.key;
							}
						}
				 	}
				}
				
				var createThirdStep = function(ti, voModality, key, index, third){
					var step = createStep(key+index);	
					var fieldPos = 0;
        			step.annexaFormly.model.voModality = voModality;
					step.annexaFormly.model.documentationToProvide = ti;
					step.annexaFormly.model.index = index;
        			var dataListNoGroup = $linq(voModality.voModalityDataList).where("x => x.chooseGroup == null").toArray();
        			if(voModality.voModalityDataList.length > 0) {
        				step.annexaFormly.fields.push({
        					key: '',
                            type: 'annexaLabel',
                            templateOptions: {
                                label: '',
                                value:'',
                                labelClass: 'p-x-0 _700 text'
                            },
                            data: {
                                informed: true,
                                row: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller: function ($scope, $filter) {
                                $scope.to.label = $filter("translate")("global.literals.newInteroperableSpecificValues");
                            }
        	        	});
        				fieldPos = fieldPos + 1;
        			}
        			_.forEach(dataListNoGroup ,function(value) {
        				factory.addFromlyFieldModalityStep(value, step, fieldPos);
            			fieldPos = fieldPos + 1;
                    });
        			
        			var dataListGroup = $linq(voModality.voModalityDataList).where("x => x.chooseGroup != null").toArray();
        			var groupName = $linq(dataListGroup).distinctBy("x => x.chooseGroup").select("x => x.chooseGroup").toArray();
        			
        			_.forEach(groupName ,function(group) {
        				var dataListElements = $linq(dataListGroup).where("x => x.chooseGroup == '" + group + "'").toArray();
        				var elementsName = $linq(dataListElements).distinctBy("x => x.chooseElement").select(function(x) {
                            return { value: x.chooseElement }
                        }).toArray();
        				
        				step.annexaFormly.fields.push({
        	        		key: group.replace(/\s+/g, ''),
        	                type: 'annexaSelectRow',
        	                className: 'col-sm-12',
        	                data: {
        	                    row:true,
        	                    informed: true,
        	                    clear: function($event,model,key, $select) {
                                    $event.stopPropagation();
                                    model[key] = undefined;
                                    if($select) {
                                        $select.selected = undefined;
                                        $select.search = undefined;
                                    }
                                }
        	                },
        	                templateOptions: {
        	                    label: group,
        	                    required: true,
        	                    focus: false,
        	                    ngOptions: 'option[to.valueProp] as option in to.options',
            					optionsAttr: 'bs-options',
            					valueProp: 'value',
            					labelProp: 'value',
            					options: elementsName
        	                }
        	        	});
        				fieldPos = fieldPos + 1;
        				_.forEach(elementsName ,function(name) {
            				var elements = $linq(dataListElements).where("x => x.chooseElement == '" + name.value + "'").toArray();
            				_.forEach(elements ,function(value) {
                				factory.addFromlyFieldModalityStep(value, step, fieldPos);
                				step.annexaFormly.fields[fieldPos].hideExpression = function ($viewValue, $modelValue, scope) {
                					if(!(value.chooseElement == scope.model[value.chooseGroup.replace(/\s+/g, '')])) {
                						$viewValue = undefined;
                						$modelValue = undefined;
                						scope.model[value.packageUbication.replaceAll(".", "")] = undefined;
                					}
			                    	return !(value.chooseElement == scope.model[value.chooseGroup.replace(/\s+/g, '')]);
			                    }
                    			fieldPos = fieldPos + 1;
                            });
                        });
                    });	
					return step;			
				}
				var promises = [];
	        	promises.push(this.getModalitiesAndDataFromService(voModalitiesToSearch));
				if(globalsInteroperable && globalsInteroperable.length > 0 && dossier && dossier.id){			
					promises.push(tramNewFactory.getDossierThirds(dossier.id));
				}
	            $q.all(promises).then(function(data) {
					if(data && data[0] && data[0].length > 0){
						data[0] = JSOG.decode(data[0]);
						var steps = [];
						if(globalsInteroperable && globalsInteroperable.length > 0 && data.length > 1 && data[1].length > 0){
							data[1] = JSOG.decode(data[1]);
							var modalThirds = [];
							if(data[1] && data[1].length > 0){
								_.forEach(data[1] ,function(third) {
					            	if(third && third.third && third.third.thirdType == 'PHISICAL' || third.third.thirdType == 'LEGAL'){
					            		modalThirds.push(third.third);
					            	}
					            });
							}
							_.forEach(globalsInteroperable, function(ti, index){
								if(ti){
									var documentationIdService = ((ti.procedureDocumentationProvide && ti.procedureDocumentationProvide.idService)?ti.procedureDocumentationProvide.idService:((ti.archiveClassificationDocumentationProvide && ti.archiveClassificationDocumentationProvide.idService)?ti.archiveClassificationDocumentationProvide.idService:undefined));
									var documentationModality = ((ti.procedureDocumentationProvide && ti.procedureDocumentationProvide.modality)?ti.procedureDocumentationProvide.modality:((ti.archiveClassificationDocumentationProvide && ti.archiveClassificationDocumentationProvide.modality)?ti.archiveClassificationDocumentationProvide.modality:undefined));
									var voModality = $linq(data[0]).firstOrDefault(undefined, "x => x.idService == "+documentationIdService+" && x.codeModality == '"+documentationModality+"'");
									if(voModality){
										var step = createThirdStep(ti, voModality, 'citizenValuesGlobal', index);
										var thirdField = createThirdField(modalThirds);
										if(step && step.annexaFormly && step.annexaFormly.fields && thirdField){
											step.annexaFormly.fields.unshift(thirdField);
											step.annexaFormly.fields.unshift({
					        					key: '',
					                            type: 'annexaLabel',
					                            templateOptions: {
					                                label: '',
					                                value:'',
					                                labelClass: 'p-x-0 _700 text'
					                            },
					                            data: {
					                                informed: true,
					                                row: true,
					                                colClass: ' col-sm-12',
					                                labelClass: 'label-strong'
					                            },
					                            controller: function ($scope, $filter) {
					                                $scope.to.label = $filter("translate")("global.literals.newInteroperableThirdValues");
					                            }
					        	        	});
											addDocumentFields(step, ti, module, dossier);
											steps.push(step);
										}
									}
								}
							});
						}
						if(thirdsInteroperable && thirdsInteroperable.length > 0){
							_.forEach(thirdsInteroperable, function(ti, index){
								if(ti && ti.third && ti.document){
									var documentationIdService = ((ti.document.procedureDocumentationProvide && ti.document.procedureDocumentationProvide.idService)?ti.document.procedureDocumentationProvide.idService:((ti.document.archiveClassificationDocumentationProvide && ti.document.archiveClassificationDocumentationProvide.idService)?ti.document.archiveClassificationDocumentationProvide.idService:undefined));
									var documentationModality = ((ti.document.procedureDocumentationProvide && ti.document.procedureDocumentationProvide.modality)?ti.document.procedureDocumentationProvide.modality:((ti.document.archiveClassificationDocumentationProvide && ti.document.archiveClassificationDocumentationProvide.modality)?ti.document.archiveClassificationDocumentationProvide.modality:undefined));
									var voModality = $linq(data[0]).firstOrDefault(undefined, "x => x.idService == "+documentationIdService+" && x.codeModality == '"+documentationModality+"'");
									if(voModality){
										var step = createThirdStep(ti, voModality, 'citizenValuesThird', index);
										if(step){
											addDocumentFields(step, ti.document, module, dossier);
											steps.push(step);
										}
									}
								}
							});							
						}
						if(steps && steps.length > 0){
							var modal = angular.copy(globalModals.newMassiveInteroperableWizardModal);
			            	modal.alerts = [];
			            	modal.wizard.modal = modal;
			                modal.documentFactory = factory;
							modal.wizard.steps = steps;
							AnnexaFormlyFactory.showModal('newMassiveInteroperableWizardModal', modal, commitModal, false, true)
						}else{
            				DialogsFactory.error($filter('translate')('global.errors.noDocumentsToCreate'), $filter('translate')('DIALOGS_ERROR_MSG'));
						}
					}else{
        				DialogsFactory.error($filter('translate')('global.errors.voDocumentErrorNoServicesRecived'), $filter('translate')('DIALOGS_ERROR_MSG'));
					}
				}).catch(function(error){
    				DialogsFactory.error($filter('translate')('global.errors.voDocumentErrorNoServicesRecived'), $filter('translate')('DIALOGS_ERROR_MSG'));
				});
			}else{
				DialogsFactory.error($filter('translate')('global.errors.voDocumentErrorNoServicesRecived'), $filter('translate')('DIALOGS_ERROR_MSG'));
			}
        }

        factory.addFromlyFieldModality = function(value, wizard, fieldPos) {
        	wizard.steps[1].annexaFormly.fields.push({
        		key: value.packageUbication.replaceAll(".", ""),
                type: '',
                className: 'col-sm-12',
                data: {
                    row:true
                },
                templateOptions: {
                    label: value.name + ' (' + value.description + ')',
                    required: value.required,
                    focus: false
                }
        	});
			
			switch (value.dataType) {
                case 'String':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'text';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Boolean':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaRadioCheckboxRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'radio';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.ngOptions = 'option[to.valueProp] as option in to.options';
    				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.optionsAttr = 'bs-options';
    				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.valueProp = 'value';
    				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.labelProp = 'label';
    				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.options = [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }];
                    break;
                case 'Long':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'decimal';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Integer':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'number';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Double':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'decimal';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
				case 'Date':
                	wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = 'text';
                	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
			}
			
			if(value.defaultValue) {
				var defaultValue = JSON.parse(value.defaultValue.replaceAll("\\", ""));
				
				if(defaultValue && defaultValue.type) {
					if(defaultValue.type == 'select') {
						wizard.steps[1].annexaFormly.fields[fieldPos].type = 'annexaSelectRow';
						wizard.steps[1].annexaFormly.fields[fieldPos].data.informed = true;
						wizard.steps[1].annexaFormly.fields[fieldPos].data.clear = function($event,model,key, $select) {
                            $event.stopPropagation();
                            model[key] = undefined;
                            if($select) {
                                $select.selected = undefined;
                                $select.search = undefined;
                            }
                        }
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.ngOptions = 'option[to.valueProp] as option in to.options';
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.optionsAttr = 'bs-options';
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.valueProp = 'id';
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.labelProp = 'value';
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.options = defaultValue.values;
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.placeholder = '';
        				wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.type = undefined;
                    	wizard.steps[1].annexaFormly.fields[fieldPos].templateOptions.maxlength = undefined;
					} else {
						wizard.steps[1].annexaFormly.model.value.packageUbication = defaultValue.values[0].id;
					}
				}
			}
        }

		factory.addFromlyFieldModalityStep = function(value, step, fieldPos) {
        	step.annexaFormly.fields.push({
        		key: value.packageUbication.replaceAll(".", ""),
                type: '',
                className: 'col-sm-12',
                data: {
                    row:true
                },
                templateOptions: {
                    label: value.name + ' (' + value.description + ')',
                    required: value.required,
                    focus: false
                }
        	});
			
			switch (value.dataType) {
                case 'String':
                	step.annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'text';
                	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Boolean':
                	step.annexaFormly.fields[fieldPos].type = 'annexaRadioCheckboxRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'radio';
                	step.annexaFormly.fields[fieldPos].templateOptions.ngOptions = 'option[to.valueProp] as option in to.options';
    				step.annexaFormly.fields[fieldPos].templateOptions.optionsAttr = 'bs-options';
    				step.annexaFormly.fields[fieldPos].templateOptions.valueProp = 'value';
    				step.annexaFormly.fields[fieldPos].templateOptions.labelProp = 'label';
    				step.annexaFormly.fields[fieldPos].templateOptions.options = [{ value: 'true', label: $filter('translate')('global.literals.yes') }, { value: 'false', label: $filter('translate')('global.literals.no') }];
                    break;
                case 'Long':
                	step.annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'decimal';
                	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Integer':
                	step.annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'number';
                	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
                case 'Double':
                	step.annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'decimal';
                	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
				case 'Date':
                	step.annexaFormly.fields[fieldPos].type = 'annexaInputRow';
                	step.annexaFormly.fields[fieldPos].templateOptions.type = 'text';
                	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = 500;
                    break;
			}
			
			if(value.defaultValue) {
				var defaultValue = JSON.parse(value.defaultValue.replaceAll("\\", ""));
				
				if(defaultValue && defaultValue.type) {
					if(defaultValue.type == 'select') {
						step.annexaFormly.fields[fieldPos].type = 'annexaSelectRow';
						step.annexaFormly.fields[fieldPos].data.informed = true;
						step.annexaFormly.fields[fieldPos].data.clear = function($event,model,key, $select) {
                            $event.stopPropagation();
                            model[key] = undefined;
                            if($select) {
                                $select.selected = undefined;
                                $select.search = undefined;
                            }
                        }
        				step.annexaFormly.fields[fieldPos].templateOptions.ngOptions = 'option[to.valueProp] as option in to.options';
        				step.annexaFormly.fields[fieldPos].templateOptions.optionsAttr = 'bs-options';
        				step.annexaFormly.fields[fieldPos].templateOptions.valueProp = 'id';
        				step.annexaFormly.fields[fieldPos].templateOptions.labelProp = 'value';
        				step.annexaFormly.fields[fieldPos].templateOptions.options = defaultValue.values;
        				step.annexaFormly.fields[fieldPos].templateOptions.placeholder = '';
        				step.annexaFormly.fields[fieldPos].templateOptions.type = undefined;
                    	step.annexaFormly.fields[fieldPos].templateOptions.maxlength = undefined;
					} else {
						step.annexaFormly.model.value.packageUbication = defaultValue.values[0].id;
					}
				}
			}
        }
        
        factory.getVODocumentValues = function(documentData, step) {
        	var calculateCFTGroups = function(newGroups){
            	var groups = [];
            	if(newGroups){
            		_.forEach(newGroups, function(ng){
            			_.forEach(ng.groups, function(g){
            				g.groups = calculateCFTGroups(g.newGroups); 
            				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
            				groups.push(g);
            			})
            		})
            	}
            	return groups;
            }
        	
        	var document = {
                    createdDate: new Date(),
                    modifyDate: new Date(),
                    dateLastAccess: new Date(),
                    urgent: false,
                    favorite: false,
                    downloaded: false,
                    sentMail: false,
                    type: {id: documentData.typeSelect},
                    section: {id: documentData.section},
                    archiveClassification: {id: documentData.archiveClassification},
                    profiles: [],
                    module: documentData.module,
                    isPublic: false,
                    dateDocumentCreation: new Date(),
                    createUser: { id: $rootScope.LoggedUser.id },
                    modifyUser: { id: $rootScope.LoggedUser.id },
                    userLastAccess: { id: $rootScope.LoggedUser.id }
                };
        	
        	_.forEach(documentData.profiles, function(item) {
                document.profiles.push({ profile: { id: item } });
            });
            if(document.profiles.length == 0 && documentData.profile){
                document.profiles.push({ profile: { id: documentData.profile } });
            }
            if (documentData.archiveClassification && documentData.archiveClassification.$selected && documentData.archiveClassification.$selected.id) {
                document.archiveClassification = { id: documentData.archiveClassification.$selected.id };
            }
            if (documentData.template && documentData.template.$selected && documentData.template.$selected.id) {
                document.template = { id: documentData.template.$selected.id };
            }
            
            var existName = false;
            if (documentData.title) {
                document.name = documentData.title;
                existName = true;
            } else {
                var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == "+documentData.typeSelect);

                document.name = selectedType ? selectedType[Language.getActiveColumn()] : '';
            }
            document.sentMail = existName;
            
            if (documentData.inputType == 'file') {
                if(!document.name && documentData.file) {
                    document.name = documentData.file.fileName;
                } else if(!document.name && documentData.fileName) {
                    document.fileName = documentData.fileName;
                    document.name = documentData.fileName;
                } else if(documentData.fileName) {
                    document.fileName = documentData.fileName;
                }
            }

            if (documentData.inputType == 'template') {
                if (documentData.template && documentData.template.id) {
                    var aux = HelperService.findFirstElementWhere(GlobalDataFactory.templateTree, 'id', documentData.template.id, -1);
                    if (aux != -1) {
                        document.template = {id: aux.idOriginal};
                    }
                }
            }
            
            var cf = CustomFieldFactory.getModelValues(documentData.documentCustomFields);

            document.customFields = [];

            var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);

            if(documentType && cf && cf.length > 0) {
                _.forEach(documentType.customFields, function(docTypeField) {
                	var groupField = undefined;
                	if(step && step.annexaFormly && step.annexaFormly.fields){
                		var docCFFields = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
						if(docCFFields && docCFFields.fieldGroup){
							groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
						}
                	}
                	var docCustomField = undefined;
                	if(groupField && groupField.data && groupField.data.customField){
                		docCustomField = angular.copy(groupField.data.customField);
                	}else{
                		docCustomField = angular.copy(docTypeField);	
                	}
					docCustomField.id = undefined;
					docCustomField.customField = { id: docTypeField.customField.id };
					docCustomField.documentType = { id: documentType.id };
					docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
                    var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

                    if(cfValue) {
                        if(docTypeField.customField.frontendType == 'CHECKBOX'){
                            var custom_field_selected = $linq(cf).where(function(x){
                                if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                            ).toArray();
                            if(custom_field_selected && custom_field_selected.length > 0){
                                docCustomField.value = '[';
                                _.forEach(custom_field_selected, function (item, key) {
                                    if(item.value) {
                                        if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
                                            var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
                                                if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                            ).toArray();
                                            if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                            }
                                        }
                                    }
                                });
                                docCustomField.value += ']';
                            }
                        }else if(Array.isArray(cfValue.value)) {
                            if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                docCustomField.value = '[';

                                _.forEach(cfValue.value, function (item) {
                                    docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
                                });

                                docCustomField.value += ']';
                            } else {
                                docCustomField.value = cfValue.value[0];
                            }
                        } else {
                            if(cfValue.value instanceof Date) {
                                docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                            } else {
                            	if (cfValue && cfValue.value && cfValue.value.toString().includes("\\")) {
                            		cfValue.value = cfValue.value.replaceAll("\\", "\\\\");
                    			}
                                docCustomField.value = cfValue.value;
                            }
                        }
                    }
                    document.customFields.push(docCustomField);
                })
            }

            document.csv = Language.getActiveColumn();
            
        	return document;
        }

        factory.getDocumentModal = function(data, self, preCloseFunction, archiveClassification, transaction, files, modalTitle, modalIcon, documentType, hideStep1Title, onlyOne, disableDocumentType, dossierSection){
            var finishFromScannerWizard = function() {
                $rootScope.$broadcast('fromScannerUploadDocuments', { modal: this });
            }
            if(self.config && self.config.module && self.config.module == "REGSALIDA") {
            	self.module = self.config.module;
            }

            var modal = angular.copy(globalModals.fromScannerWizard);
			if(onlyOne){
				modal = angular.copy(globalModals.oneFromScannerWizard);
			}
            if(hideStep1Title) {
                modal.wizard.steps[0].title = '';
            }
            if(modalTitle){
                modal.title = modalTitle;
            }
            if(modalIcon){
                modal.icon = modalIcon;
            }
            if(files && files.length > 0){
                modal.wizard.filesToUpload = files;
            }
            _.forEach(modal.wizard.steps, function (step, key) {
                modal.wizard.steps[key].annexaFormly.model = {};
                modal.wizard.steps[key].annexaFormly.options = {};
            });
			if(!onlyOne){
	            var titleAll = $filter("translate")("global.documents.uploadDocuments");
	            var newDocumentModalAll = factory.getOpenNewDocumentModal(undefined, undefined, self.module, titleAll, undefined, archiveClassification, documentType, transaction, undefined, true, undefined, undefined, true, dossierSection);
	            if(newDocumentModalAll && newDocumentModalAll.annexaFormly && newDocumentModalAll.annexaFormly.fields && newDocumentModalAll.annexaFormly.fields.length > 0 && newDocumentModalAll.annexaFormly.fields[0].fieldGroup){
                	var docTypeField = $linq(newDocumentModalAll.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
                	if(docTypeField){
                		docTypeField.templateOptions.modal_body_key = 'documentAllInOne';
					}
				}
	            newDocumentModalAll.annexaFormly.model.modal_body.fileName = titleAll;
                newDocumentModalAll.annexaFormly.model.modal_body.modal_body_key = 'documentAllInOne';
                modal.wizard.steps.push({
	                title: titleAll,
	                key: 'documentAllInOne',
	                disabled: true,
	                annexaFormly: newDocumentModalAll.annexaFormly,
                    disableNext: function(model, wizard) {
                    	try{
                    		var key = this.key;
                    		var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == key); });
    	                    if (indexNextStep != undefined && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
    	                    	var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == key); });
	                    		if(indexStep != undefined && model && model['modal_body'] && model['modal_body'].typeSelect && !model['modal_body'].origDocumentType){
	                    			if(model['modal_body'].modal_body_key && this.key && model['modal_body'].modal_body_key === this.key && !model['modal_body'].callToModifyCustomFields){
	                    				model['modal_body'].callToModifyCustomFields = true;
	                    				factory.modifyCustomFields(model['modal_body'].typeSelect, wizard.steps[indexStep].annexaFormly,'modal_body','modal_body');
                                    }
	                    		}
    	                    }
                    	}catch(e){
                    		 console.log(e);
                    	}
                        return false;
					},
	                exitValidation: function (model, step, wizard) {
	                    var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == step.key); });
	                    if (indexNextStep && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
	                        if (step.annexaFormly.form) {
	                            step.annexaFormly.form.$setSubmitted();
	
	                            if (!step.annexaFormly.form.$valid) {
	                                return false;
	                            }
	                        }
	                    } else {
	                        return false;
	                    }
	                },
	                beforeNext: function (step, wizard) {
	                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == step.key); });
	                    if (indexStep != -1 && indexStep + 1 < wizard.steps.length) {
	                        var documentsSelected = $linq(Object.keys(wizard.steps[0].annexaFormly.model.documents)).where(function(x){
	                            if(wizard.steps[0].annexaFormly.model.documents[x]){
	                                return true;
	                            }else{
	                                return false;
	                            }
	                        }).toArray();
	
	                        var scannerDocuments = [];
	
	                        _.forEach(documentsSelected, function (doc) {
	                            var model = angular.copy(wizard.steps[indexStep].annexaFormly.model.modal_body);
	                            model.title = doc;
	                            model.fileName = doc;
	                            model.procState = 'pending';
	                            model.error = '';
	                            if(wizard.filesToUpload && wizard.filesToUpload.length > 0){
	                                var indexFile = $linq(wizard.filesToUpload).indexOf(function (x) { return (x.name == doc); });
	
	                                if(indexFile != -1) {
	                                    model.file = wizard.filesToUpload[indexFile];
	                                }
	                            }
	                            scannerDocuments.push(model);
	                        });
	
	                        wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.documents = scannerDocuments;
	                        wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.files = wizard.filesToUpload;
	                    }
	                }
	            });
			}
            _.forEach(data, function (item) {
                var newDocumentModal = factory.getOpenNewDocumentModal(undefined, undefined, self.module, item, undefined, archiveClassification, documentType, transaction, modal.wizard.steps.length, undefined, undefined, undefined, true, dossierSection);
                if(newDocumentModal && newDocumentModal.annexaFormly && newDocumentModal.annexaFormly.fields && newDocumentModal.annexaFormly.fields.length > 0 && newDocumentModal.annexaFormly.fields[0].fieldGroup){
                	var docTypeField = $linq(newDocumentModal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'typeSelect'");
                	if(docTypeField){
                		docTypeField.templateOptions.modal_body_key = item;
                		if( disableDocumentType){
							docTypeField.templateOptions.disabled = true;
						}
					}
				}
                newDocumentModal.annexaFormly.model['modal_body_' + modal.wizard.steps.length].fileName = item;
                newDocumentModal.annexaFormly.model['modal_body_' + modal.wizard.steps.length].modal_body_key = item;
                var currentStep = modal.wizard.steps.length;
                modal.wizard.steps.push({
                    title: item,
                    key: item,
                    disabled: true,
                    annexaFormly: newDocumentModal.annexaFormly,
                    disableNext: function(model, wizard) {
                    	try{
                    		var key = this.key;
                    		var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == key); });
    	                    if (indexNextStep != undefined && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
    	                    	var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == key); });
	                    		if(indexStep != undefined && model && model['modal_body_' + indexStep] && model['modal_body_' + indexStep].typeSelect && !model['modal_body_' + indexStep].origDocumentType){
	                    			if(model['modal_body_' + indexStep].modal_body_key && this.key && model['modal_body_' + indexStep].modal_body_key === this.key && !model['modal_body_' + indexStep].callToModifyCustomFields){
	                    				model['modal_body_' + indexStep].callToModifyCustomFields = true;
	                    				factory.modifyCustomFields(model['modal_body_' + indexStep].typeSelect, wizard.steps[indexStep].annexaFormly, 'modal_body_' + indexStep, 'modal_body_' + indexStep);
                                    }
	                    		}
    	                    }
                    	}catch(e){
                    		 console.log(e);
                    	}
                        return false;
					},
	                exitValidation: function (model, step, wizard) {
                        var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == step.key); });
                        if (indexNextStep && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
                            if (step.annexaFormly.form) {
                                step.annexaFormly.form.$setSubmitted();

                                if (!step.annexaFormly.form.$valid) {
                                    return false;
                                }
								var docNameError = HelperService.getErrorMessageValidatePattern();
                                if (!modal.isLastStep && !factory.validDocumentName(step.annexaFormly.model['modal_body_' + currentStep].title, step.annexaFormly.model['modal_body_' + currentStep].typeSelect)) {
                                    if ($linq(modal.alerts).count("x => x.msg == '"+docNameError+"'") == 0) {
                                        modal.alerts.push({msg: docNameError});
                                    }
                                    return false;
                                } else {
                                    var indexAlert = $linq(modal.alerts).indexOf("x => x.msg == '"+docNameError+"'");

                                    if (indexAlert != -1) {
                                        modal.alerts.splice(indexAlert, 1);
                                    }
                                }
                            }
                        } else {
                            return false;
                        }
                    },
                    beforeNext: function (step, wizard) {
                        var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == step.key); });
                        if (indexStep != -1 && indexStep + 1 < wizard.steps.length) {
                            var lastStep = false;

                            if (indexStep == wizard.steps.length - 2) {
                                lastStep = true;
                            } else {
                                var penidngDocuments = $linq(wizard.steps).skip(indexStep + 1).where(function (x) {
                                    if (_.contains(Object.keys(wizard.steps[0].annexaFormly.model.documents), x.key) && !x.disabled) {
                                        return true;
                                    }

                                    return false;
                                }).toArray();

                                if (penidngDocuments.length == 0) {
                                    lastStep = true;
                                }
                            }
                            modal.isLastStep = lastStep;
                            if (lastStep) {
                                var selectedDocuments = $linq(wizard.steps).where(function (x) {
                                    if (_.contains(Object.keys(wizard.steps[0].annexaFormly.model.documents), x.key) && !x.disabled) {
                                        return true;
                                    }

                                    return false;
                                }).toArray();

                                var scannerDocuments = [];

                                _.forEach(selectedDocuments, function (doc) {
                                    var indexDoc = $linq(wizard.steps).indexOf(function (x) { return (x.key == doc.key); });

                                    if (indexDoc != -1) {
                                        var model = doc.annexaFormly.model['modal_body_' + indexDoc];
                                        model.procState = 'pending';
                                        model.error = '';
                                        if(wizard.filesToUpload && wizard.filesToUpload.length > 0){
                                            var indexFile = $linq(wizard.filesToUpload).indexOf(function (x) { return (x.name == doc.key); });

                                            if(indexFile != -1) {
                                                model.file = wizard.filesToUpload[indexFile];
                                            }
                                        }
                                        scannerDocuments.push(model);
                                    }
                                });

                                wizard.steps[wizard.steps.length - 1].annexaFormly.fields[0].data.documents = scannerDocuments;
                            }
                        }
                    }
                });
            });
            modal.wizard.steps.push({
                title: '',
                key: 'uploadingDocuments',
                disabled: false,
                disableNext: function () {
                    return this.annexaFormly.form.$invalid;
                },
                afterNext: function (step, wizard) {
                    modal.isLastStep = false;
                },
                annexaFormly: {
                    fields: [
                        {
                            key: 'upload',
                            type: 'annexaComponent',
                            templateOptions: {
                                type: 'annexa-scanner-documents'
                            },
                            controller: ['$scope', function ($scope) {
                                var a = 0;
                            }],
                            data: {
                                documents: [],
                                module: self.module,
                                rowClass: 'm-b-xs row',
                                labelStrong: true
                            }
                        }
                    ]
                }
            })
            var selectableDocuments = [];

            modal.wizard.steps[0].annexaFormly.model.documents = {};
            _.forEach(data, function (item) {
                selectableDocuments.push({ id: item, value: item });
                if(!onlyOne){
					modal.wizard.steps[0].annexaFormly.model.documents[item] = (files && files.length > 0)? true : false;
				}
            });

            if(!onlyOne && files && files.length > 0){
                modal.wizard.steps[0].annexaFormly.model.isUploadDocuments = true;
            }
			if(onlyOne){
            	modal.wizard.steps[0].annexaFormly.fields[0].templateOptions.options = selectableDocuments;
			}else{
				modal.wizard.steps[0].annexaFormly.fields[1].templateOptions.options = selectableDocuments;
			}
            modal.wizard.modal = modal;
            modal.preClose = preCloseFunction;

            AnnexaFormlyFactory.showModal('fromScannerWizardModal', modal, finishFromScannerWizard, false, true)
        }

        factory.getOpenNewDocumentModal = function (tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, wizard_step, wizard_AllInOne, documentTypesAux, requiredAndCannotCreateDocument, notCallmodifyCustomFields, dossierSection) {
            var modal = angular.copy(globalModals.documentNew);

            if(wizard_step) {
                modal.annexaFormly.fields[0].key += '_' + wizard_step;
            }

            var modal_body_key = modal.annexaFormly.fields[0].key;
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.modal_body_key = modal_body_key;

            if(title && title.lastIndexOf(".") != -1) {
            	title = title.substring(0, title.lastIndexOf("."));
            }

            var typeSelect = {};
            var inputType = 'file';
            if(documentTypesAux && documentTypesAux.length > 0){
                typeSelect = documentTypesAux[0];
                inputType = 'template';
                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.optionsAux = documentTypesAux;
            }else{
	            if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0) {
	                if(!typeToSelect) {
	                    if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPR.id
	                    		&& extra && extra.transactionType && extra.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id && extra.proposalSubtype != 'DECREE') {
	                    	if(requiredAndCannotCreateDocument && requiredAndCannotCreateDocument.length > 0){
								var dtypesWithoutSPR = angular.copy(GlobalDataFactory.documentTypes);
								dtypesWithoutSPR = $linq(dtypesWithoutSPR).where("x => x.id != "+$rootScope.app.configuration.sec_document_types.SPR.id[0]).toArray();
								modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.optionsAux = dtypesWithoutSPR;
								if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
									factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
								}
								var errorFields = "<br/><br/>";
								_.forEach(requiredAndCannotCreateDocument, function(req){
									errorFields = errorFields + "    "+$filter('translate')(req)+"<br/>"; 
								});
								DialogsFactory.error($filter('translate')('global.sec.literals.proposalRequiredFields')+errorFields);
							}else{
								factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPR.id[0]);
							}
	                    } else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.SPD.id
	                    		&& extra && extra.transactionType && extra.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id && extra.proposalSubtype == 'DECREE') {
	                    	if(requiredAndCannotCreateDocument && requiredAndCannotCreateDocument.length > 0){
								var dtypesWithoutSPR = angular.copy(GlobalDataFactory.documentTypes);
								dtypesWithoutSPR = $linq(dtypesWithoutSPR).where("x => x.id != "+$rootScope.app.configuration.sec_document_types.SPD.id[0]).toArray();
								modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.optionsAux = dtypesWithoutSPR;
								if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
									factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
								}
								var errorFields = "<br/><br/>";
								_.forEach(requiredAndCannotCreateDocument, function(req){
									errorFields = errorFields + "    "+$filter('translate')(req)+"<br/>"; 
								});
								DialogsFactory.error($filter('translate')('global.sec.literals.proposalRequiredFields')+errorFields);
	                		}else{
								factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.SPD.id[0]);
							}
	                    } else if ($rootScope.app.configuration && $rootScope.app.configuration.sec_document_types && $rootScope.app.configuration.sec_document_types.STR.id
	                			&& extra && extra.transactionType && extra.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
	                		factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.sec_document_types.STR.id);
	                	} else if ($rootScope.app.configuration && $rootScope.app.configuration.general_document_type && $rootScope.app.configuration.general_document_type.value) {
	                        factory.typeDefault = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.general_document_type.value);
	                    }
	                }
	
	                typeSelect = typeToSelect ? typeToSelect : (factory.typeDefault ? factory.typeDefault : GlobalDataFactory.documentTypes[0]);
	                inputType = typeSelect.documentFileAttachment != 'NOT_REQUIRED' ? 'file' : (typeSelect.documentTemplateSelection != 'NOT_REQUIRED' ? 'template' : 'file');
	            }
            }
            
            if(module && module == "REGSALIDA" && typeSelect && !typeSelect.includeRegisterEntry) {
            	if(documentTypesAux && documentTypesAux.length > 0){
            		_.forEach (documentTypesAux, function(docType){
            			if(docType.includeRegisterEntry){
            				typeSelect = docType;
            				return false;
                    	}
                	})
            	} else if(GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0) {
            		_.forEach (GlobalDataFactory.documentTypes, function(docType){
            			if(docType.includeRegisterEntry){
            				typeSelect = docType;
            				return false;
                    	}
                	})
            	}
            }

            modal.annexaFormly.model = {};
            var profileAux = HelperService.getDefultProfileFor('DOC', module, factory.profilesUser, $rootScope.LoggedUser.userDefaultEntityProfiles, extra);
            var haveOneProfile = HelperService.getOneProfileFor('DOC', module, factory.profilesUser, $rootScope.LoggedUser.userDefaultEntityProfiles, extra);
            modal.annexaFormly.model[modal_body_key] = {
                typeSelect:  typeSelect.id,
                template: {},
                title: (($rootScope.esetMode)? '' : ((title && title.length > 199) ? title.substring(0, 199) : title)),
                dateDocumentCreation: new Date(),
                archiveClassification: HelperService.treeSelected(archiveClassification) ? archiveClassification.model : {},
                section:(dossierSection ? dossierSection.id: (factory.sectionsUser.length == 1 ? factory.sectionsUser[0].id : undefined)),
                profiles: ((module != 'EXPED')?((profileAux)?[profileAux]:[]) : ((haveOneProfile)?[profileAux]:[])),
                module: module,
                extra: extra,
                showFile: true,
                showTemplate: !($rootScope.esetMode && (module == 'REGENTRADA' || module == 'REGSALIDA')) ? true : false,
				showPhysical: false,
                inputType:inputType,
                add_document_type_and_creation_date_to_document_name: false,
                tramArchiveClassification: HelperService.treeSelected(archiveClassification),
                modifyArchiveClassification: false,
                oneProfile: haveOneProfile,
                oneSection: factory.sectionsUser.length == 1,
                wizard: (wizard_step) ? true : false,
                wizard_AllInOne: (wizard_AllInOne) ? true : false, 
                documentCustomFields: {}, 
                origDocumentType:undefined,
                modal_body_key: modal_body_key
            };

            if(!modal.annexaFormly.model[modal_body_key].modifyArchiveClassification && !modal.annexaFormly.model[modal_body_key].tramArchiveClassification && modal.annexaFormly.model[modal_body_key].inputType == 'file') {
                if(typeSelect && typeSelect.archiveClassification) {
                    modal.annexaFormly.model[modal_body_key].archiveClassification.$selected = { id: typeSelect.archiveClassification.id, title: typeSelect.archiveClassification[Language.getActiveColumn()] };
                }
            }

            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.module = module ? module : 'DOC';
            modal.extra = tableDocs ? tableDocs : undefined;
            modal.commitFunction = commitFunction ? commitFunction : undefined;
            modal.folder = folder;

            if(typeSelect && typeSelect.id && !notCallmodifyCustomFields){
            	factory.modifyCustomFields(typeSelect.id, modal.annexaFormly, modal_body_key);
            }
            return modal;
        }

        factory.openNewDocument = function (tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, documentTypes, relatedDocumentId, requiredAndCannotCreateDocument, dossierSection){
            var modal = factory.getOpenNewDocumentModal(tableDocs, commitFunction, module, title, folder, archiveClassification, typeToSelect, extra, undefined, undefined, documentTypes, requiredAndCannotCreateDocument, undefined, dossierSection);
            if(modal && relatedDocumentId){
            	modal.relatedDocumentId = relatedDocumentId;
            }
            AnnexaFormlyFactory.showModal('modalDocumentNew', modal, factory.saveNewDocument, false, undefined);
        };

        factory.saveNewDocument = function(){
            if (this.annexaFormly.form.$valid) {
                var self = this;
                var model = this.annexaFormly.model.modal_body;
                var documentFields = angular.copy(this.annexaFormly.fields[0].fieldGroup);
                factory.createNewDocument(documentFields, model, self.module, self.folder, self.relatedDocumentId)
                    .then(function(data) {
                        if(self.folder) {
                            $rootScope.$broadcast('documentsViewModeChange', { id: self.folder.documentViewModeType.id, selected: self.folder });
                        }

                        if(self.extra) {
                            self.extra.reloadInternalData(true);
                        } else if (self.commitFunction) {
                            self.commitFunction(data);
                        } else {
                            $state.transitionTo('annexa.doc.documents.view', {"code": data.id});
                        }

                        self.close();
                    }).catch(function(error) {
                        self.alerts.push(error)
                });
            }
        };
        
        factory.openDownloadDocuments = function() {
            var modal = angular.copy(globalModals.downloadDocumentsModal);

            modal.languageColumn = Language.getActiveColumn();
            
            modal.documentsToDownload = [];
            
            if(!modal.search) {
            	modal.search = {};
            }

            modal.search.addedSelect = 'x => x.document.id';
            modal.search.origin = 'doc';
            modal.search.type = 'Document';
            modal.search.processSearch = function (object) {
                return object.name;
            }
            
            modal.removeDocument = function ($index) {
                modal.documentsToDownload.splice($index, 1);
            }
            
            modal.searchedValue = undefined;
        	modal.placeholder = 'global.literals.placeholser_search_documents';
        	modal.processSearch = function (object) {
        		return object.name;
            }
        	modal.searchFunc = function(val) {
                var added = [];
                if(modal.documentsToDownload && modal.documentsToDownload.length > 0){
                	added = $linq(modal.documentsToDownload).select("x => x.id").toArray();
                }
                var filter = { name: val.val };
                var additionalFilter = { exceptState: 'NEW', documentBoxNormalSearch: true };
                additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                additionalFilter.added = added;
                additionalFilter.langColumn = Language.getActiveColumn();

                return RestService.filterData('doc', 'Document', filter, additionalFilter, 0, 10, '', 3, 'box')
                    .then(function (data) {
                        var response = [];
                        _.forEach(data.data.content, function(value, key) {
                            response.push({
                                id: value.id,
                                object: JSOG.decode(value),
                                value: modal.processSearch(JSOG.decode(value))
                            });
                        });
                        response = new SortedArray(response, 'value').sort();
                        return response;
                    });
            }
        	
        	modal.advancedSearch = function() {
           	 var docStatusRender = function(data, type, full, meta) {
                    var content = '';

                    if(factory.statuses && factory.statuses.length > 0) {
                        var status = $linq(factory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                        if(status) {
                            content += '<div class="text-center">';
                            content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                            content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                            content += '    </span>';
                            content += '</div>';
                        }
                    }

                    return content;
                }
           	
               var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
               
               var advancedSearchModal = {
                   title: 'global.literals.advanced_search_documents',
                   size: 'modal-lg',
                   filter: [
                       { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                       { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                       { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
                       { id: 'internalDoc', type: 'checkbox', order: 3, label: 'global.documents.internalDocument', callAux: true, model: true },
                       { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + modal.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
                       { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + modal.languageColumn).toArray(), addAll: true, nameProperty: modal.languageColumn, userChoosen: true },
                       { id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: modal.languageColumn, userChoosen: true },
                       { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: modal.languageColumn, callAux: true, userChoosen: true },
                       { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
                       { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
                       { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
                       { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
                       { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true },
                       { id: 'related', type: 'checkbox', order: 9, label: 'global.literals.showRelateds', callAux: true, preFilter:true, negated:false}
                   ],
                   tableDefinition: {
                       id: 'tableAdvancedSearchDocuments',
                       origin: 'doc',
                       objectType: 'Document',
                       sortName: 'name',
                       sort: [[3, 'asc']],
                       columns: [
                       	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
                           { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', modal.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                           { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
                           { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
                           { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
                           { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
                       ], 
                       noLoading: true,
                       skipFirstSearch: true,
                       callAuxToAdd: { added: $linq(modal.documentsToDownload).select("x => x.id").toArray() }
                   },
                   multiple: true
               }

               AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDocuments', advancedSearchModal);
           }
        	
        	$rootScope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASDocuments') {
                    var id = undefined;
                    
                    if(args.selected && args.selected.length > 0){
                    	modal.searchedValue = { id: id, object: args.selected };
                    	modal.searchAdd();
                    }

                    args.modal.close();
                }
            });
        	
        	
        	modal.typeaheadSelect = function (item) {
                if (item && item.value.indexOf('</i>') > 0) {
                    item.value = item.value.substring(item.value.indexOf('</i>') + 4, item.value.length);
                }
            }
        	modal.addText = 'global.literals.add';
        	modal.addedSelect = 'x => x.id';
        	modal.document = {};
        	modal.searchAdd = function () {
        		var searchedDoc = [];
                if(modal.searchedValue && modal.searchedValue.object) {
                	
                	if(Array.isArray(modal.searchedValue.object)) {
                		_.forEach(modal.searchedValue.object, function(obj){
                    		var code = obj.id; 
                        	if(obj.createdDate) { 
                        		code = new Date(obj.createdDate).getFullYear().toString().substr(-2) + obj.id; 
                        	}
                            modal.document = {
                                id: obj.id,
                                code: code,
                                title: obj.name
                            };

                            modal.documentsToDownload.push(modal.document);
                		});
                	} else {
                        var code = modal.searchedValue.id; 
                        if(modal.searchedValue.object.createdDate) { 
                            code = new Date(modal.searchedValue.object.createdDate).getFullYear().toString().substr(-2) + modal.searchedValue.id; 
                        }
                        modal.document = {
                            id: modal.searchedValue.id,
                            code: code,
                            title: modal.searchedValue.value
                        };

                        modal.documentsToDownload.push(modal.document);
                	}
                	
                	
                	
                    modal.searchedValue = null;
                }
            }
        	
            modal.submitLabel = 'global.literals.downloadDocs';
            modal.submitFunction = function() {
                var self = this;
                self.alerts.length = 0;
                var send = {};
                if (modal.documentsToDownload) {
                    var docs = [];
                    angular.forEach(modal.documentsToDownload, function (value) {
                        if (value) {
                        	docs.push({id:value.id});
                        }
                    });
                    docs = $linq(docs).orderBy("x => x.id").toArray();
                    send.documents = JSOG.encode(docs);
                }
                send.languageColumn = Language.getActiveColumn();
                if(!docs || docs.length == 0){
                    var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})};
                    self.alerts.push({msg: errorTranslate.msg});
                }else {
                    $http({
                        url: './api/doc/generateZip',
                        method: 'POST',
                        data: send,
                        headers: {
                            accept: 'application/zip'
                        },
                        responseType: 'arraybuffer',
                        cache: false
                    }).then(function (data, status, headers) {
                        var contentType = data.headers('content-type');
                        if (!contentType) {
                            contentType = 'application/zip';
                        }
                        var name = data.headers('content-disposition');
                        if (name) {
                            name = name.split(";");
                            if (name && name.length > 1) {
                                name = name[1].trim().split("=");
                                if (name && name.length > 1) {
                                    name = name[1];
                                    name = name.replace(/"/g, '');
                                } else {
                                    name = "documents.zip";
                                }
                            } else {
                                name = "documents.zip";
                            }
                        } else {
                            name = "documents.zip";
                        }
                        var file = new Blob([data.data], {type: contentType});
                        if (window.navigator.msSaveOrOpenBlob) {
                            try {
                                window.navigator.msSaveOrOpenBlob(file, name);
                            } catch (e) {
                                console.log(e.message);
                            }
                        } else {

                            var linkElement = document.createElement('a');
                            try {
                                var url = window.URL.createObjectURL(file);
                                linkElement.setAttribute('href', url);
                                linkElement.setAttribute("download", name);
                                var clickEvent = new MouseEvent("click", {
                                    "view": window,
                                    "bubbles": true,
                                    "cancelable": false
                                });
                                linkElement.dispatchEvent(clickEvent);
                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                        self.close();
                    }).catch(function (error) {
                        var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)};
                        self.alerts.push({msg: errorTranslate.msg});
                    });
                }
            }
        	
            AnnexaModalFactory.showModal('modalDownloadDocuments', modal);
        }

        factory.createNewDocument = function(modalDocumentFields, model, module, folder, relatedDocumentId) {
            var deferred = $q.defer();

            var moduleDoc = 'DOC';

            if(factory.validDocumentName(model.title, model.typeSelect)) {
                if (module) {
                    moduleDoc = module;
                }

                var document = {
                    createdDate: new Date(),
                    modifyDate: new Date(),
                    dateLastAccess: new Date(),
                    urgent: false,
                    favorite: false,
                    downloaded: false,
                    sentMail: false,
                    type: {id: model.typeSelect},
                    section: {id: model.section},
                    profiles: [],
                    module: moduleDoc,
                    dateDocumentCreation: model.dateDocumentCreation,
					expirationDate: model.expirationDate
                };

                _.forEach(model.profiles, function(item) {
                    document.profiles.push({ profile: { id: item } });
                });
                if(document.profiles.length == 0 && model.profile){
                    document.profiles.push({ profile: { id: model.profile } });
                }
                if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
                    document.archiveClassification = { id: model.archiveClassification.$selected.id };
                }

                if (model.template && model.template.$selected && model.template.$selected.id) {
                    document.template = { id: model.template.$selected.id };
                }
                var existName = false;

                if (model.title) {
                    document.name = model.title;
                    existName = true;
                } else {
                    var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == "+model.typeSelect);

                    document.name = selectedType ? selectedType[Language.getActiveColumn()] : '';
                }
                document.sentMail = existName;

                if (model.inputType == 'file') {
                    if(!document.name && model.file) {
                        document.name = model.file.fileName;
                    } else if(!document.name && model.fileName) {
                        document.fileName = model.fileName;
                        document.name = model.fileName;
                    } else if(model.fileName) {
                        document.fileName = model.fileName;
                    }
                }

                if (model.inputType == 'template') {
                    if (model.template && model.template.id) {
                        var aux = HelperService.findFirstElementWhere(GlobalDataFactory.templateTree, 'id', model.template.id, -1);
                        if (aux != -1) {
                            document.template = {id: aux.idOriginal};
                        }
                    }
                }

				if (model.inputType == 'physical') {
					document.physical = true;
					document.physicalLength = model.physicalLength;
					document.physicalWidth = model.physicalWidth;
					document.physicalDepth = model.physicalDepth;
					document.physicalWeight = model.physicalWeight;
					document.physicalVolume = model.physicalVolume;
					document.physicalUnits = model.physicalUnits;  
					document.physicalSupport = model.physicalSupport;  
					document.physicalLocation = model.physicalLocation;  
					document.weightUnits = model.weightUnits;  
					document.volumeUnits = model.volumeUnits;                    
                }

                var cf = CustomFieldFactory.getModelValues(model.documentCustomFields);

                document.customFields = [];

                var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);

                if(documentType && cf && cf.length > 0) {
                	var calculateCFTGroups = function(newGroups){
                    	var groups = [];
                    	if(newGroups){
                    		_.forEach(newGroups, function(ng){
                    			_.forEach(ng.groups, function(g){
                    				g.groups = calculateCFTGroups(g.newGroups); 
                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                    				groups.push(g);
                    			})
                    		})
                    	}
                    	return groups;
                    }
                	_.forEach(documentType.customFields, function(docTypeField) {
                		var groupField = undefined;
						if(modalDocumentFields){
							var docCFFields = $linq(modalDocumentFields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
							if(docCFFields && docCFFields.fieldGroup){
								groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
							}
						}
                    	var docCustomField = undefined;
                    	if(groupField && groupField.data && groupField.data.customField){
                    		docCustomField = angular.copy(groupField.data.customField);
                    	}else{
                    		docCustomField = angular.copy(docTypeField);	
                    	}
						docCustomField.id = undefined;
						docCustomField.customField = { id: docTypeField.customField.id };
						docCustomField.documentType = { id: documentType.id };
						docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
//                        var docCustomField = angular.copy(docTypeField);
//                        docCustomField.id = null;
//                        docCustomField.customField = { id: docCustomField.customField.id };
//                        docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
                        var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

                        if(cfValue) {
                            if(docTypeField.customField.frontendType == 'CHECKBOX'){
                                var custom_field_selected = $linq(cf).where(function(x){
                                    if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
                                ).toArray();
                                if(custom_field_selected && custom_field_selected.length > 0){
                                    docCustomField.value = '[';
                                    _.forEach(custom_field_selected, function (item, key) {
                                        if(item.value) {
                                            if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
                                                var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
                                                    if(item.id.endsWith("_"+x.value)){return true}else{return false}}
                                                ).toArray();
                                                if(custom_field_value_selected && custom_field_value_selected.length > 0){
                                                    docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
                                                }
                                            }
                                        }
                                    });
                                    docCustomField.value += ']';
                                }
                            }else if(Array.isArray(cfValue.value)) {
                                if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
                                    docCustomField.value = '[';

                                    _.forEach(cfValue.value, function (item) {
                                        docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
                                    });

                                    docCustomField.value += ']';
                                } else {
                                    docCustomField.value = cfValue.value[0];
                                }
                            } else {
                                if(cfValue.value instanceof Date) {
                                    docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
                                } else {
                                	if (cfValue && cfValue.value && cfValue.value.toString().includes("\\")) {
                                		cfValue.value = ((cfValue.value.replaceAll)?cfValue.value.replaceAll('\\','\\\\'):cfValue.value.replace(/\\/g,'\\\\')); 
                                	}
                                    docCustomField.value = cfValue.value;
                                }
                            }
                        }
                        document.customFields.push(docCustomField);
                    })
                }

                document.csv = Language.getActiveColumn();
                if(relatedDocumentId && relatedDocumentId > 0){
                	$http({
                        method: 'POST',
                        url: './api/doc/generate_from_content/' + relatedDocumentId,
                        data: JSOG.encode(document)
                    }).success(function(data, status) {
                        deferred.resolve(JSOG.decode(data));
                    }).error(function(msg,code) {
                        deferred.reject(msg);
                    });

                }else if(model && model.templateDefaultBooks && model.templateDefaultBooks.$selected){
                	deferred.resolve(document);
                }else{
	                RestService.insertMultiPartData('./api/doc/with_files', document, model.file)
	                    .then(function(data) {
	                        deferred.resolve(JSOG.decode(data));
	                    }).catch(function(error) {
	                    if (error && error.message && error.message.toUpperCase().indexOf('ALFRESCO') != -1) {
	                        deferred.reject({msg: 'global.literals.errorSaveGD'});
	                    } else if(error && error.code && error.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							deferred.reject({msg: 'global.tram.errors.processTemplate', msgAux:((error.message)?error.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						} else {
	                        deferred.reject({ msg: 'global.literals.errorNewDoc' })
	                    }
	                });
                }
            } else {
                deferred.reject({ msg: HelperService.getErrorMessageValidatePattern() });
            }

            return deferred.promise;
        }

        //endregion

        //region General (OK, Repasat)

        factory.updateDocument = function(document){
            var deferred = $q.defer();
            var request = {
                method: 'PUT',
                url: './api/doc/' + document.id,
                data: JSOG.encode(document)
            }
            if(factory.isArchivedDossier) {
                request.headers = { archiveDossier: true }
            }
     
            $http(request).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.deleteDocument = function (id) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/' + id,
                method: 'DELETE'
            }).then(function () {
                deferred.resolve();
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateDocumentCustomField = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/doc/' + customField.document.id + '/custom_field',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.updateDocumentCustomFieldGroup = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/doc/documentCustomFieldGroup',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        factory.newDocumentCustomFieldGroup = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: './api/doc/documentCustomFieldGroup',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        factory.deleteDocumentCustomFieldGroup = function(customFieldId){
            var deferred = $q.defer();

            $http({
                method: 'DELETE',
                url: './api/doc/documentCustomFieldGroup/' + customFieldId
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };
        
        factory.getDocumentFromScanner = function () {
            var deferred = $q.defer();

            $http({
                url: './api/doc/scanner',
                method: 'GET'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.updateDocumentFile = function(files, id) {
            var deferred = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            $http({
                url: './api/doc/' + id + '/filemultipartfile',
                method: 'PUT',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        factory.addAttachments = function(attachments, id) {
            var deferred = $q.defer();
            
            var formData = new FormData();
            if(Array.isArray(attachments)){
            	_.forEach(attachments, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', attachments);
            }
            $http({
                method: 'POST',
                url: './api/doc/' + id + '/attachmentmultipartfile',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteAttachment = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'DELETE',
                url: './api/doc/attachment/' + id
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        factory.getAttachments = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/'+ id +'/attachment'
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        factory.getTemporalDocumentURL = function(id, mimetype, fileName) {
            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: './api/repodocs/' + window.btoa(id) + '/temp_url',
                data: JSON.stringify({ mimetype: mimetype, fileName: fileName }),
                responseType: "arraybuffer"
            }).then(function(response) {
                if(response.headers && response.headers()['view_or_download']) {
                    deferred.resolve({ type: response.headers()['view_or_download'], data: response.headers()['url_view'] ? response.headers()['url_view'] : response.data, contentType: response.headers()['content-type'] });
                } else {
                    deferred.reject(response);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.definitiveDocument = function(document){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + document.id + '/definitive',
                data: JSOG.encode(document)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.definitiveDocuments = function(documents){
            var deferrend = $q.defer();

            var ids = '';

            _.forEach(documents, function (item) {
                ids += (ids != '' ? ',' : '') + item;
            });

            $http({
                method: 'PUT',
                url: './api/doc/' + ids + '/definitives',
                data: JSOG.encode(documents)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.returnToDraftDocument = function(id){
            var deferrend = $q.defer();
            $http({
                url: './api/doc/' + id + '/draft',
                method: "PUT"
            }).success(function (res) {
                deferrend.resolve(res);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        }

        factory.getDocContent = function(id, printDocument) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/' + id + '/content'+((printDocument)?'/'+printDocument:'/false')
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.getAuthCopyContent = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/' + id + '/authCopy/true'
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.getScanDocumentContent = function(id) {
            var deferred = $q.defer();

            $http({
                method: 'GET',
                url: './api/doc/scanner/' + id + '/content'
            }).then(function(data) {
                deferred.resolve(data.data.url);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateFavorite = function (id, favorite) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/' + id + '/favorite/'+favorite,
                method: "PUT"
            }).then(function() {
                deferrend.resolve(true);
            }).catch(function(error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.getEditOnlineUrl = function(code,type) {
            var deferrend = $q.defer();
            $http({
                url: './api/repodocs/editUrl',
                method: "GET",
                params: {
                    id: code
                }
            }).success(function (res) {
            	$rootScope.$broadcast('annexaDocumentAuditExecute', {id:res.id});
                switch (type) {
                    case 'microsoft':
                        deferrend.resolve(res.editOnlineUrl);
                        break;
                    case 'libreoffice':
                        deferrend.resolve(res.editOnlineUrlLO);
                        break;
                    default:
                        deferrend.resolve(res.editOnlineUrl);
                        break;
                }
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.canEdit = function(document, isArchived) {
            if(document && document.docStatus) {
                if(!_.contains(['NEW', 'NEW_AND_ATTACHMENT'], document.docStatus)) {
                	if (_.contains(['PENDING_SIGN'], document.docStatus) && 
                			$rootScope.app.configuration.can_edit_doc_pending_sign && 
                			$rootScope.app.configuration.can_edit_doc_pending_sign.value) {
                    	return true;
                	} else if (isArchived){
                		return true;
                	} else {
                    	return false;
                	}
                }
            }

            return true
        }
        
        factory.canEditTitle = function(canEditDocument, isArchived) {
        	if (canEditDocument) {
        		return true;
        	} else if ($rootScope.app.configuration.can_always_edit_doc_title && 
        			$rootScope.app.configuration.can_always_edit_doc_title.value){
        		return true;
        	} else if(isArchived){
        		return true;
        	}
        	
        	return false;
        }
        
        factory.callCloudSignAndorra = function(idRowToSign, documentsIdToSign, signProcessActionIds, locationPrefix, nrt, description, certType) {        	
            var deferrend = $q.defer();
            $http({
            	method: 'POST',
            	url: './api/repodocs/callCloudSignAndorra',            		
            	data: JSON.stringify({ idRowToSign: idRowToSign, documentsId: documentsIdToSign, signProcessActionIds: signProcessActionIds, locationPrefix: locationPrefix, nrt: nrt, description: description, certType: certType, idUser: $rootScope.LoggedUser.id })
            }).success(function (res) {
            	deferrend.resolve(res);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.callfinishCloudSignAndorra = function(documentId, url) {
            var deferrend = $q.defer();
            $http({
            	method: 'POST',
            	url: './api/repodocs/callfinishCloudSignAndorra',            		
            	data: JSON.stringify({ documentId: documentId, url: url })
            }).success(function (res) {
            	deferrend.resolve(res.url);
            }).error(function(msg,code){
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.newRelatedDocument = function(id, newRelatedDocument){
        	 var deferred = $q.defer();

             $http({
                 url: './api/doc/' + id + '/documents',
                 method: 'POST',
                 data: JSOG.encode(newRelatedDocument)
             }).then(function(data) {
                 deferred.resolve(JSOG.decode(data.data))
             }).catch(function(error) {
                 deferred.reject(error);
             });

             return deferred.promise;
        }
        
    	factory.deleteRelatedDocument = function(id, removedId){
    		 var deferred = $q.defer();

             $http({
                 url: './api/doc/' + id + '/documents/' + removedId,
                 method: 'DELETE'
             }).then(function (data) {
                 deferred.resolve(data);
             }).catch(function (error) {
                 deferred.reject(error);
             });

             return deferred.promise;
    	}
    	
    	factory.canManuallyAddStampDecree = function(documentId){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/doc/' + documentId + '/canManuallyAddStampDecree',
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.manuallyAddStampDecree = function(documentId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/manuallyAddStampDecree',
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.manuallyAddStampRegister = function(documentId, registerId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/manuallyAddStampRegister/' + registerId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.getServicesVO = function () {
            var deferred = $q.defer();

            $http({
                url: './api/doc/getServicesVO',
                method: 'GET'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getModalityAndDataFromService = function (nbService, nbModalidad) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/getModalityAndDataFromService/' + nbService + '/' + nbModalidad,
                method: 'GET'
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

		factory.getModalitiesAndDataFromService = function (voModalitiesToSearch) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/getModalityAndDataFromService',
                method: 'POST',
                data: JSOG.encode(voModalitiesToSearch)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getVODocument = function (voModality, documentData, transaction, register) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/getVODocument',
                method: 'POST',
                data: {
                	voModality: JSOG.encode(voModality),
                	documentData: JSOG.encode(documentData),
                	user: JSOG.encode($rootScope.LoggedUser)
                }
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
    	
		factory.getVODocuments = function (documents) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/getVODocuments',
                method: 'POST',
                data: JSOG.encode(documents)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.newDocumentThird = function(documentId, thirdId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/addThird/'+thirdId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.newDocumentRegisterEntry = function(documentId, regEntryIds){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/addRegEntry/'+CommonService.getParameterList(regEntryIds),
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.newDocumentDossierTransaction = function(documentId, dossierTransactionIds){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/addDossierTransaction/'+CommonService.getParameterList(dossierTransactionIds),
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.newDocumentSession = function(documentId, sessionIds){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/addSession/'+CommonService.getParameterList(sessionIds),
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteDocumentThird = function(documentId, thirdId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/deleteThird/'+thirdId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteDocumentRegisterEntry = function(documentId, regEntryId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/deleteRegEntry/'+regEntryId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteDocumentDossierTransaction = function(documentId, dossierTransactionId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/deleteDossierTransaction/'+dossierTransactionId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteDocumentSession = function(documentId, sessionId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/deleteSession/'+sessionId,
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
		factory.regenerateDocument = function(documentId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + documentId + '/regenerateDocument',
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
		//endregion

		factory.updateDocumentType = function(document){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/doc/' + document.id + '/updateDocumentType',
				data: JSOG.encode(document)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

		factory.updateMetadata = function(metadata){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/docEMGDE/' + metadata.id + '/emgdeDocMetadata',
				data: JSOG.encode(metadata)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

		factory.updateMetadataObj = function(metadataObj, metadataId, metadataType){
            var deferrend = $q.defer();
            $http({
                method: 'POST',
                url: './api/docEMGDE/' + metadataId + '/'+metadataType,
				data: JSOG.encode(metadataObj)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

		factory.deleteMetadataObj = function(metadataObjId, metadataId, metadataType){
            var deferrend = $q.defer();
            $http({
                method: 'DELETE',
                url: './api/docEMGDE/' + metadataId + '/'+metadataType+'/'+metadataObjId
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };


		factory.associateThirdDocuments = function(documents, thirds, origin){
			if(thirds && thirds.length > 0){
				var thirdsId = $linq(thirds).where("x => x.third && x.third.id").select("x => x.third.id").toArray();
				$http({
	                method: 'GET',
	                url: './api/thirds/relatedDocuments/'+ CommonService.getParameterList(thirdsId)
	            }).success(function(data, status) {
					if(data){
						var docs = JSOG.decode(data);
						if(docs && docs.length > 0){
							var modal = angular.copy(globalModals.thirdAddDocumentRelated);
				            modal.annexaFormly.model = {};
				            modal.annexaFormly.model.modal_body = {};
				            modal.annexaFormly.options = {};
							var thirdField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedDocuments'");
				            if(thirdField && thirdField.data){
								thirdField.data.thirds = thirds;
                                thirdField.data.documents = documents;
								thirdField.data.relateds = docs;
							}
							var saveDocumentsRelatedsToThird = function(){
								var self = this;
								if(self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup){
									var thirdField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'relatedDocuments'");
									if(thirdField && thirdField.data && thirdField.data.values){
										var ths = $linq(thirdField.data.values).where("x => x.selected === true").toArray()	
										if(ths && ths.length > 0){
											var docsToAdd = [];
											_.forEach(ths, function(th){
												if(th.docs){
													var docs = $linq(th.docs).where("x => x.selected === true").toArray();
													if(docs && docs.length > 0){
														_.forEach(docs, function(doc){
															var existDoc = $linq(docsToAdd).firstOrDefault(undefined, "x => x.id === "+doc.id);
															if(!existDoc){
																docsToAdd.push(doc);
															}
														});
													}
												}
											});	
											if(docsToAdd && docsToAdd.length > 0){
												if(origin === 'tram'){
													$rootScope.$broadcast('associateThirdDocumentsTramFinish', { modal: self, docs:docsToAdd, origin: origin });
												}else{
													$rootScope.$broadcast('associateThirdDocumentsRegFinish', { modal: self, docs:docsToAdd, origin: origin });
												}
											}else{
												DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoSelectedError'));
											}
										}else{
											DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoSelectedError'));
										}
									}else{
										DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoSelectedError'));
									}
								}else{
									DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoSelectedError'));
								}
							}
							AnnexaFormlyFactory.showModal('modalAddThirdDocumentRelated', modal, saveDocumentsRelatedsToThird, false);
						}else{
							DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoDocumentError'));
						}
					}else{
						DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentNoDocumentError'));
					}
	            }).error(function(msg,code) {
					DialogsFactory.error($filter('translate')('global.literals.associateThirdRelatedDocumentError'));
	            });
			}else{
				DialogsFactory.error($filter('translate')('global.literals.associateThirdDocumentError'));
			}
		};
        
		factory.updateFormCustomFields = function(documentCustomFieldsField, documentType, form, formModel){
			var deferrend = $q.defer();
			if(documentType && documentType.id && documentType.customFields && documentType.customFields.length > 0){
				factory.calculateDocumentTypeCustomField(documentType.id).then(function(dataCTTCF){
					if(dataCTTCF){
						var descriptionLanguageColumn = "descriptionL" + Language.getActiveColumn().substr(1, Language.getActiveColumn().length);
		                var updateGroupDocumentTypeCFListValues = function(groups, ttId){
		            		if(groups){
		            			_.forEach(groups, function(group){
		            				if(ttId && dataCTTCF.queryInitialCFGs && dataCTTCF.queryInitialCFGs[ttId]){
		            					if(dataCTTCF.queryInitialCFGs[ttId][group.id]){
		            						var labelPropField = [];
		            						if(group.labelPropField){
		            							labelPropField = group.labelPropField.split("--@--@--");
		            						}
		            						var lvaluesActual = [];
		            						if(group.identifierField && labelPropField && labelPropField.length > 0){
		            							_.forEach(dataCTTCF.queryInitialCFGs[ttId][group.id], function(lv){
		            								if(lv[group.identifierField]){
		            									var label = '';
		            									_.forEach(labelPropField, function(lp){
		            										label = label + ((label)?((group.labelFieldsSeparator)?group.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
		            									});
		            									if(group.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && group.parentField && lv[group.parentField]){
		            										var cflvadded = {
		            											id:lv[group.identifierField],
		            											language1:label,
		            											language2:label,
		            											language3:label,
		            											value:lv[group.identifierField],
		            											parentValue:{id:lv[group.parentField]}
		            										}
		            										lvaluesActual.push(cflvadded);
		            									}else{
		            										var cflvadded = {
		            											id:lv[group.identifierField],
		            											language1:label,
		            											language2:label,
		            											language3:label,
		            											value:lv[group.identifierField]
		            										}
		            										lvaluesActual.push(cflvadded);
		            									}
		            									if(group.relatedCustomFields && group.relatedCustomFields.length > 0){
		            										_.forEach(group.relatedCustomFields, function(rc){
		            											if(rc.customFieldValue && rc.customFieldTemplateTag){
		            												cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
		            											}
		            										})
		            									}
		            								}
		            							});
		            							group.relatedCustomField = angular.copy(group.relatedCustomField);
		    									group.relatedCustomField.listValues = lvaluesActual;
		            						}
		            					}
		            					if(group.groups){
		            						updateGroupDocumentTypeCFListValues(group.groups, ttId);
		                                }
		            				}
		            			})
		            		}
		                }
	                    var setCustomFields = function(field){
	                        if(documentType && documentType.id && dataCTTCF.queryInitialCFs && dataCTTCF.queryInitialCFs[documentType.id]){
	                        	if(dataCTTCF.queryInitialCFs[documentType.id][field.id]){
	                        		if(field.customField && field.customField.fromQuery && field.customField.query && field.customField.query.id){
	            						var labelPropField = [];
	            						if(field.labelPropField){
	            							labelPropField = field.labelPropField.split("--@--@--");
	            						}
	            						var lvaluesActual = [];
	            						if(field.identifierField && labelPropField && labelPropField.length > 0){
	            							_.forEach(dataCTTCF.queryInitialCFs[documentType.id][field.id], function(lv){
	            								if(lv[field.identifierField]){
	            									var label = '';
	            									_.forEach(labelPropField, function(lp){
	            										label = label + ((label)?((field.labelFieldsSeparator)?field.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	            									});
	            									if(field.customField.frontendType === 'STRUCTURAL_SELECT' && field.parentField && lv[field.parentField]){
	            										var cflvadded = {
	            											id:lv[field.identifierField],
	            											language1:label,
	            											language2:label,
	            											language3:label,
	            											value:lv[field.identifierField],
	            											parentValue:{id:lv[field.parentField]}
	            										}
	            										lvaluesActual.push(cflvadded);
	            									}else{
	            										var cflvadded = {
	            											id:lv[field.identifierField],
	            											language1:label,
	            											language2:label,
	            											language3:label,
	            											value:lv[field.identifierField]
	            										}
	            										lvaluesActual.push(cflvadded);
	            									}
	            									if(field.relatedCustomFields && field.relatedCustomFields.length > 0){
	            										_.forEach(field.relatedCustomFields, function(rc){
	            											if(field.customFieldValue && field.customFieldTemplateTag){
	            												cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
	            											}
	            										})
	            									}
	            								}
	            							});
	            							field.customField = angular.copy(field.customField);
											field.customField.listValues = lvaluesActual
	            						}
	            					}
	                        	}
	                        }
	                        if(field.groups){
	                        	updateGroupDocumentTypeCFListValues(field.groups, documentType.id)
	                        }
	                        var formCF = new AnnexaFormly();
	                        formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, documentType.id + '_' + field.customField.id, field.noEditable, documentType, "DOCUMENT_TYPE", true, field, documentType.customFields);
	                        if(formCF && formCF.fields && formCF.fields.length > 0){
	                        	if(field[descriptionLanguageColumn]){
	                        		formCF.fields[0].templateOptions.label = formCF.fields[0].templateOptions.label + ' ('+ field[descriptionLanguageColumn] + ')';
	                        	}
	                        	formCF.fields[0].className = 'col-sm-12';
	                        	documentCustomFieldsField.fieldGroup.push(formCF.fields[0]);
	                        	if(formCF.fields.length > 1){
	                        		formCF.fields[1].className = 'col-sm-12';
	                        		documentCustomFieldsField.fieldGroup.push(formCF.fields[1]);
	                        	}
	                        	if(formModel && formModel.documentCustomFields){
	                        		formModel.documentCustomFields['cf_' + documentType.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, formModel.taskCustomFields, ('cf_' + documentType.id + '_' + field.customField.id));
	                        	}
	                        }
	                    }
	                    var custom_fields = $linq(documentType.customFields).orderBy("x => x.viewOrder").toArray();
						_.forEach(custom_fields, function (customField) {
							setCustomFields(customField);
						});
					}
					var returnData = {haveDocumentCustomFields: false};
        			if(documentCustomFieldsField && documentCustomFieldsField.fieldGroup && documentCustomFieldsField.fieldGroup.length > 0){
        				returnData.haveDocumentCustomFields = true;
        			}
        			deferrend.resolve(returnData);
				}).catch(function (error) {
					deferrend.reject("Error get custom fields");
                });
			}else{
				deferrend.reject("No objectId");
			}
			return deferrend.promise;
        }
		
		factory.calculateDocumentTypeCustomField = function (documentTypeId) {
            var deferred = $q.defer();

            $http({
                url: './api/doc/document_type/calculateCustomFields/'+documentTypeId+'/true/-1',
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };
        
        factory.modifyCustomFields = function (documentTypeId, form, modal_body_key, modal_field_key) {
        	var documentType = undefined;
        	var formModel = ((form && form.model && modal_body_key && form.model[modal_body_key])?form.model[modal_body_key]:((form && form.model)?form.model:undefined));
        	if(documentTypeId){
        		documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == " + documentTypeId);
        		if(documentType && documentType.id){
        			documentType = angular.copy(documentType);
        		}
        	}
        	if((!documentType && formModel.origDocumentType) || (documentType && formModel.origDocumentType !== documentType.id)){
        		if(formModel.documentCustomFields){
        			var keys = Object.keys(formModel.documentCustomFields);
                    _.forEach(keys, function(key){
                    	delete formModel.documentCustomFields[key];
                    });
        		}else{
        			formModel.documentCustomFields = {};
        		}
        		if(documentType && documentType.customFields){
        			var deleteValueFromGroups = function(groups){
        				if(groups && groups.length > 0){
        					_.forEach(groups, function(g){
        						if(g.groups && g.groups.length > 0){
        							deleteValueFromGroups(g.groups);
        						}else{
        							g.value = undefined;
        						}
        					});
        				}
        			}
        			_.forEach(documentType.customFields, function(ttcf){
        				if(ttcf.groups && ttcf.groups.length > 0){
        					deleteValueFromGroups(ttcf.groups);
        				}
        			});
        		}
        		formModel.documentCustomFieldsShow = false;
        		var documentCustomFieldsField = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
        		if(documentCustomFieldsField && documentCustomFieldsField.fieldGroup){
        			documentCustomFieldsField.fieldGroup.length = 0;
        		}else { 
        			if(modal_field_key){
        				var fieldParent = $linq(form.fields).firstOrDefault(undefined, "x => x.key == '"+modal_field_key+"'");
        				if(fieldParent && fieldParent.fieldGroup){
	        				documentCustomFieldsField = $linq(fieldParent.fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
		        			if(documentCustomFieldsField && documentCustomFieldsField.fieldGroup){
		            			documentCustomFieldsField.fieldGroup.length = 0;
		            		}
        				}
        			}
        			if(!documentCustomFieldsField && form.fields[0] && form.fields[0].fieldGroup){
	        			documentCustomFieldsField = $linq(form.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
	        			if(documentCustomFieldsField && documentCustomFieldsField.fieldGroup){
	            			documentCustomFieldsField.fieldGroup.length = 0;
	            		}
	        		}
        		}
        		if(documentType && documentType.id){
	        		factory.updateFormCustomFields(documentCustomFieldsField, documentType, form, formModel).then(function(data) {
	        			if(data.haveDocumentCustomFields){
	        				formModel.documentCustomFieldsShow = true;
	        			}
	        		}).catch(function() {
	        			documentCustomFieldsField.fieldGroup.length = 0;
	                });
        		}
        	}
        	formModel.origDocumentType = ((documentType)?documentType.id:undefined);
        };

		//endregion

        return factory;
    }]);